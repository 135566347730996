import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: [
    "CSE graduates fail basic coding interviews",
    "Skilled CSE Graduates",
  ],
  datasets: [
    {
      label: "Percentage",
      data: [80, 20],
      backgroundColor: ["#FF3131", "#6670cc"],
      borderColor: ["#FF3131", "#6670cc"],
      borderWidth: 1,
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  responsive: true,
};

export function PieChart() {
  return (
    <div className="relative w-full h-64 lg:h-96">
      <Pie data={data} options={options} />
    </div>
  );
}
