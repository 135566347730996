import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import BlogPostCard from "./BlogPostCard";
import BlogNavBar from "./BlogNavBar";
import axiosInstance from "../../Utils/AxiosConfig";
import { CircularProgress } from "@mui/material";
import NoResultsFound from "../../Utils/NoResultsFound";

const MainBlogContent = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);

  const fetchAllPosts = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(`blog/fetch`);
      const { blogs } = response.data;

      if (blogs && blogs.length > 0) {
        setAllPosts(blogs);
        filterPostsByCategory(blogs, category);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
      setHasMore(false);
    }
    setLoading(false);
  };

  const filterPostsByCategory = (blogs, category) => {
    if (category && category !== "home") {
      let filteredPosts;
      if (category === "favourite") {
        filteredPosts = blogs.filter((blog) => blog.favourite === true);
      } else {
        filteredPosts = blogs.filter((blog) => blog.category === category);
      }
      setPosts(filteredPosts);
    } else {
      setPosts(blogs);
    }
  };

  const updateBlog = (id, updatedData) => {
    setAllPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === id ? { ...post, ...updatedData } : post
      )
    );
    setPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === id ? { ...post, ...updatedData } : post
      )
    );
  };

  useEffect(() => {
    fetchAllPosts();
  }, []);

  useEffect(() => {
    filterPostsByCategory(allPosts, category);
  }, [category, allPosts]);

  if (loading && posts.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <BlogNavBar onCategorySelect={setCategory} />
      {posts.length === 0 && !loading ? (
        <NoResultsFound />
      ) : (
        <InfiniteScroll
          dataLength={posts.length}
          next={() => {}}
          hasMore={hasMore}
          loader={
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          }
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4"
        >
          {posts.map((post) => (
            <BlogPostCard key={post.id} blog={post} updateBlog={updateBlog} />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default MainBlogContent;
