import React from "react";

// single box component
const SingleDiv = ({ title, icon, description }) => (
  <>
    <div
      className="flex flex-col space-y-2 items-center p-8 justify-center bg-white rounded-xl"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      {/* icon  */}
      <div className="text-6xl text-csb-500 font-light">{icon}</div>
      {/* title  */}
      <h2 className="text-2xl text-center text-csb-500 font-bold">{title}</h2>
      {/* description  */}
      <p className="text-xs text-gray-500 text-justify">{description}</p>
    </div>
  </>
);
export default SingleDiv;
