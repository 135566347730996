import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ErrorPage from "../../Utils/ErrorPage";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const BlogPostCard = ({ blog, updateBlog }) => {
  const [liked, setLiked] = useState(blog.favourite);
  const [updating, setUpdating] = useState(false);

  const toggleLike = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const response = await axios.put(`/blog/update/${blog.id}`, {
        favourite: !liked,
      });
      if (response.status === 200) {
        const newLikedStatus = !liked;
        setLiked(newLikedStatus);
        updateBlog(blog.id, { favourite: newLikedStatus });
      } else {
        console.error("Failed to update like status");
      }
    } catch (error) {
      console.error("Error updating like status:", error);
    }
    setUpdating(false);
  };

  if (!blog) {
    return <ErrorPage />;
  }

  const createdAtDate = new Date(blog.createdAt);

  return (
    <Link to={`/blog/post/${blog.id}`} state={{ blog }} className="w-full">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-4">
        <div className="flex flex-col md:flex-row p-4">
          <img
            src={blog.image}
            alt={blog.title}
            className="w-full md:w-48 h-48 rounded-md object-cover mb-4 md:mb-0 md:mr-4"
          />
          <div className="flex-grow">
            <h2 className="text-lg md:text-xl font-bold mb-2 line-clamp-2">
              {blog.title}
            </h2>
            <p className="text-gray-600 text-sm line-clamp-3">{blog.tldr}</p>
            <div className="flex flex-col md:flex-row md:justify-between mt-4">
              <p className="text-gray-500 text-sm">
                {`Written by ${
                  blog.createdBy
                } on ${createdAtDate.toLocaleDateString()}`}
              </p>
              <p className="text-gray-500 text-sm mt-2 md:mt-0">{`Category: ${blog.category}`}</p>
            </div>
          </div>
          <div className="flex flex-col items-end justify-between mt-4 md:mt-0">
            {updating ? (
              <CircularProgress size={24} />
            ) : (
              <button
                className={`p-2 rounded-full ${
                  liked ? "bg-red-500" : "bg-red-100"
                } text-white`}
                onClick={toggleLike}
                aria-label="Like this post"
              >
                <FontAwesomeIcon icon={faHeart} className="h-6 w-6" />
              </button>
            )}
            <span className="text-gray-500 text-sm">{`${blog.likes} likes`}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogPostCard;
