import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./LandingPage/Landing";
import "./App.css";
import Roadmap from "./Features/Roadmap/Roadmap";
import Hackathon from "./Hackathon/HackathonLandingPage";
import Team from "./Team/Team";
import PrivateRoute from "./Utils/PrivateRoute";
import ErrorPage from "./Utils/ErrorPage";
import BlogPostDetail from "./Features/Blog/BlogPostDetail";
import BlogPageDashboard from "./Features/Blog/BlogPageDashboard";
import VideoScreen from "./Video/VideoScreen";
import Layout from "./Utils/Layout";
import AIProgrammingCopilot from "./Features/AI Programming Copilot/AIProgrammingCopilot";
import ResumeCopilot from "./Features/ResumeCopilot/ResumeCopilot";
import Courses from "./Courses/Courses";
import Interviewer from "./Features/AI Mock Interview/Interviewer";
import Employer from "./Features/Employer/Employer";
import Employee from "./Features/Employee/Employee";
import userPageTracking from "./Analytics/userPageTracking";
import CandidateDashboard from "./Features/Candidate Dashboard/CandidateDashboard";

function App() {
  userPageTracking();

  const routes = [
    { path: "/", element: <Landing /> },
    { path: "/blog", element: <BlogPageDashboard />, isPrivate: true },
    { path: "/video/:url", element: <VideoScreen /> },
    { path: "/blog/post/:id", element: <BlogPostDetail />, isPrivate: true },
    // { path: "/blog/post/:id/edit", element: <EditBlogPost />, isPrivate: true },
    // { path: "/blog/create", element: <CreateBlogPost />, isPrivate: true },
    { path: "/team", element: <Team /> },
    { path: "/roadmap", element: <Roadmap />, isPrivate: true },
    { path: "/hackathon", element: <Hackathon /> },
    // { path: "/employer", element: <Employer />, isPrivate: false },
    // { path: "/employee", element: <Employee />, isPrivate: false },
    // {
    //   path: "/candidate-dashboard",
    //   element: <CandidateDashboard />,
    //   isPrivate: false,
    // },
    // { path: "/jarvis", element: <AIProgrammingCopilot />, isPrivate: false },
    // { path: "/resume", element: <ResumeCopilot />, isPrivate: false },
    // { path: "/interview", element: <Interviewer />, isPrivate: true },
    // { path: "/courses", element: <Courses />, isPrivate: false },
  ];

  return (
    <Router>
      <Layout>
        <Routes>
          {routes.map(({ path, element, isPrivate }) => (
            <Route
              key={path}
              path={path}
              element={
                isPrivate ? <PrivateRoute>{element}</PrivateRoute> : element
              }
              errorElement={<ErrorPage />}
            />
          ))}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
