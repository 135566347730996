import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "../PrizesSection.css";
import SectionTitle from "../LandingPage/components/SectionTitle";

const prizes = [
  {
    title: "2nd Prize",
    description: "20,000 Taka",
  },
  {
    title: "Grand Prize",
    description: "30,000 Taka",
  },
  {
    title: "People's Choice Award",
    description: "10,000 Taka",
  },
];

const PrizesSection = () => {
  return (
    <Box
      sx={{
        marginLeft: "5%",
        marginRight: "5%",
        textAlign: "center",
        pb: 16,
        pt: 8,
      }}
    >
      <Container sx={{ maxWidth: { md: "80%", lg: "65%" } }}>
        <div data-aos="fade-up" data-aos-duration="1000">
          <SectionTitle title="Prizes" />
        </div>
        <p
          className="text-sm md:text-base text-gray-700 mt-4 text-center px-4"
          data-aos="fade-up"
          data-aos-duration="2300"
        >
          Winner will win cash prize, certificate of achievement, letter of
          recommendation from the CSB Team
        </p>

        <Swiper
          className="mt-12"
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {prizes.map((prize, index) => (
            <SwiperSlide
              key={index}
              className="flex justify-center items-center"
            >
              <div
                className="prize-card bg-csb-500 rounded-lg shadow-md p-6 h-full w-full"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <Typography
                  sx={{ color: "#000000" }}
                  variant="h5"
                  component="div"
                  className="text-black font-semibold mb-2"
                >
                  {prize.title}
                </Typography>
                <Typography sx={{ color: "#ffffff" }} variant="body1">
                  {prize.description}
                </Typography>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Box>
  );
};

export default PrizesSection;
