import React from "react";
import SectionTitle from "../LandingPage/components/SectionTitle";
import { FaLinkedin } from "react-icons/fa";

const WorkshopSection = () => {
  const workshops = [
    {
      name: "Web Development",
      details: "Instructor: Abtahi Noor",
      qualification: "Software Developer at CSB",
      imageUrl: "../workshops/webDevelopment.svg",
    },
    {
      name: "Android Development",
      details: "Instructor: Mostofa Adib Shakib",
      qualification: "ex-Software Engineer at Snapchat",
      imageUrl: "../workshops/androidDevelopment.svg",
    },
    {
      name: "Ethical Hacking (Linux)",
      details: "Instructor: Tareq Ahamed",
      qualification: "Bug Bounty Hunter at HackerOne",
      imageUrl: "../workshops/security.png",
    },
  ];

  const WorkshopCard = ({ speaker }) => {
    return (
      <>
        <div
          className="flex flex-col space-y-1 justify-center items-center"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <img
            src={speaker.imageUrl}
            alt={speaker.name}
            className="w-48 h-48 rounded-full object-cover border-2 border-csb-500"
          />

          <h1 className="font-bold pt-2">{speaker.name}</h1>
          <h1 className="text-gray-700 ">{speaker.details}</h1>
          <div className="text-gray-700 text-center text-sm">
            {speaker.qualification.split(",").map((part, index) => (
              <p key={index}>{part}</p>
            ))}
          </div>
          <a
            className="hover:text-csb-500 text-xl"
            href={speaker.linkedInUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      </>
    );
  };

  return (
    <section className="max-w-screen-xl mx-auto py-12">
      <div data-aos="fade-up" data-aos-duration="1000">
        <SectionTitle title="Workshops" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-8">
        {workshops.map((speaker, index) => (
          <WorkshopCard key={index} speaker={speaker} />
        ))}
      </div>
      <p
        className="text-sm text-gray-700 text-justify mt-4"
        data-aos="fade-up"
        data-aos-duration="1300"
      >
        This certificate is awarded upon successful participation in a workshop
      </p>
    </section>
  );
};

export default WorkshopSection;
