import React from "react";
import {
  Box,
  Container,
  List,
  ListItem,
  Grid,
  Typography,
} from "@mui/material";
import TracksSection from "./TracksSection";
import JudgeSection from "./JudgeSection";
import SpeakerSection from "./SpeakerSection";
import FAQsSection from "./FAQsSection";
import WorkshopSection from "./Workshop";
import PrizesSection from "./PrizesSection";
import Colors from "../Colors";
import SectionTitle from "../LandingPage/components/SectionTitle";
import UniversityPartnershipsCarousel from "./UniversityPartnershipsCarousel";
import AnimatedCounterSection from "./AnimatedCounterSection";
import MentorSection from "./Mentor";

const HackathonLandingPage = () => {
  return (
    <Box>
      <MainSection />
      <AboutSection />
      <AnimatedCounterSection />
      <div className="bg-white">
        <UniversityPartnershipsCarousel />
      </div>
      <div className="bg-white">
        <SpeakerSection />
      </div>
      <JudgeSection />
      {/* <div className="bg-white">
        <MentorSection />
      </div> */}
      <div className="bg-white">
        <WorkshopSection />
      </div>
      <TracksSection />
      <div className="bg-white">
        <PrizesSection />
      </div>
      <div className="bg-white">
        <ScheduleSection />
      </div>
      <CertificateSection />
      <FAQsSection />
    </Box>
  );
};

const MainSection = () => {
  return (
    <Box
      sx={{
        backgroundColors: Colors.background,
        textAlign: "center",
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: "0px", md: "40px" },
      }}
    >
      <Container>
        <h1
          data-aos="fade-up"
          data-aos-duration="2000"
          className="text-6xl  md:text-7xl lg:text-9xl font-bold text-center text-csb-500"
        >
          HackCSB
        </h1>

        <div className="flex flex-col space-y-2 lg:space-y-3 items-center justify-center">
          <h3
            className="text-xl lg:text-2xl text-gray-800 "
            data-aos="fade-up"
            data-aos-duration="2100"
          >
            July 2024 | Virtual
          </h3>
          <p
            className="text-sm lg:text-xl text-gray-700"
            data-aos="fade-up"
            data-aos-duration="2200"
          >
            Innovate, Create, Collaborate
          </p>
          <p
            className="text-sm lg:text-xl text-gray-500"
            data-aos="fade-up"
            data-aos-duration="2200"
          >
            Bangladesh's first large-scale international hackathon
          </p>
        </div>
      </Container>
    </Box>
  );
};

const AboutSection = () => {
  return (
    <section className="max-w-screen-xl  mx-auto px-8 pb-14 bg-gray-200 p-4">
      <div data-aos="fade-up" data-aos-duration="1000">
        <SectionTitle title="Welcome to HackCSB" />
      </div>
      <p
        className="text-sm text-gray-700 text-justify mt-4"
        data-aos="fade-up"
        data-aos-duration="1300"
      >
        At HackCSB, you'll meet fellow Bangladeshi hackers from around
        Bangladesh, USA, Europe, Canada, learn new skills, and work alongside
        seasoned mentors. We'll have free workshops, lecture series, mentorship,
        prizes, and more. Don't have a team or even an idea? Don't worry! We'll
        give you the tools to build something incredible. HackCSB 2024 is a 1
        week online hackathon from July 12th to August 30th that brings together
        Bangladesh's brightest coders, designers, and innovators to create
        impactful solutions. Come join us!
      </p>
    </section>
  );
};

const ScheduleSection = () => {
  return (
    <section className="max-w-screen-xl  mx-auto px-8  py-12">
      <div data-aos="fade-up" data-aos-duration="1000">
        <SectionTitle title="Schedule" />
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="1200"
        className="flex flex-col items-center justify-center"
      >
        <List>
          <ListItem>
            July 12, 2024 - Opening Ceremony + Hacking Begins at 10 AM; Live Web
            Development with Abtahi Part 1 at 9 PM
          </ListItem>
          <ListItem>
            July 13, 2024 - Live Q/A with Nuzhat Minhaz from Microsoft at 10 AM;
            Live Web Development with Abtahi Part 2 at 9 PM
          </ListItem>
          <ListItem>
            July 14, 2024 - Live Linux with Tareq Part 1 at 9 PM; Live Q/A with
            Tamzid Islam from Apple at 10 PM
          </ListItem>
          <ListItem>
            July 15, 2024 - Live Linux with Tareq Part 2 at 9 PM
          </ListItem>
          <ListItem>
            July 16, 2024 - Live Android Development with Adib Shakib Part 1 at
            9 PM
          </ListItem>
          <ListItem>
            July 17, 2024 - Live Android Development with Adib Shakib Part 2 at
            9 PM
          </ListItem>
          <ListItem>
            July 18, 2024 - All participants stops hacking at 10 PM
          </ListItem>
          <ListItem>
            July 19, 2024 - Live Q/A with Seeam Noor (IBM/YouTuber) at 9 AM;
            Closing Ceremony + Winners Announced at 10 AM
          </ListItem>
          <ListItem>
            August 31, 2024 - Closing Ceremony + Winners Announced at 10 AM
          </ListItem>
        </List>
      </div>
    </section>
  );
};

const CertificateSection = () => {
  return (
    <section className="max-w-screen-xl mx-auto py-12 px-4 md:px-8">
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {/* Title Section */}
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Typography
            variant="h1"
            component="h1"
            className="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-csb-500"
          >
            Get Certified
          </Typography>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} md={6} lg={6} className="pt-8 md:pt-0">
          <Box
            className="flex justify-center items-center"
            data-aos="fade-up"
            data-aos-duration="1300"
          >
            <img
              className="w-full max-w-md h-auto object-contain"
              data-aos="fade-up"
              data-aos-duration="1300"
              src="../../certificate.png"
              alt="certificate"
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </section>
  );
};

export default HackathonLandingPage;
