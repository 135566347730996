import React from "react";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const NoResultsFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className="text-red-500 text-6xl mb-5"
      />
      <Typography variant="h4" className="mt-4 text-gray-700 mb-2">
        No Results Found
      </Typography>
      <Typography variant="body1" className="text-gray-500 mt-2">
        Try adjusting your search or filter to find what you're looking for.
      </Typography>
    </div>
  );
};

export default NoResultsFound;
