import React from "react";
import SectionTitle from "../LandingPage/components/SectionTitle";
import { FaLinkedin } from "react-icons/fa";

const JudgeCard = ({ speaker }) => {
  return (
    <>
      <div
        className="flex flex-col space-y-1 justify-center items-center"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <img
          src={speaker.imageUrl}
          alt={speaker.name}
          className="w-48 h-48 rounded-full object-cover border-2 border-csb-500"
        />

        <h1 className="font-bold pt-2">{speaker.name}</h1>
        <div className="text-gray-700 text-center text-sm">
          {speaker.qualification.split(",").map((part, index) => (
            <p key={index}>{part}</p>
          ))}
        </div>
        <a
          className="hover:text-csb-500 text-xl"
          href={speaker.linkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin />
        </a>
      </div>
    </>
  );
};

const JudgeSection = () => {
  const speakers = [
    {
      name: "Vidyasagar Ranganaboina",
      qualification:
        "ex-CTO at Agentive (YC S23), ex-ML Engineer at Google, IIT Madras Alum",
      imageUrl: "/judges/vidya.png",
      linkedInUrl: "https://www.linkedin.com/in/vidyasagarr7/",
    },
    {
      name: "Ravi Shankar",
      qualification:
        "Principal Engineering Manager at Microsoft, CS Teacher at TEALS",
      imageUrl: "/judges/ravi.png",
      linkedInUrl: "https://www.linkedin.com/in/ravisgoli/",
    },
    {
      name: "Kaushik Sathupadi",
      qualification:
        "Staff Engineer & Manager at Google, ex-Principal Architect at Yahoo, ex-Principal Engineer at Oracle",
      imageUrl: "/judges/kaushik.png",
      linkedInUrl: "https://www.linkedin.com/in/kaushik-sathupadi-3930829/",
    },
    {
      name: "Noah Ohrner",
      qualification:
        "CTO at AnswerGrid (YC S24), ex-Software Engineer at Amazon, University of Cambridge Alum",
      imageUrl: "/judges/noah.png",
      linkedInUrl: "https://www.linkedin.com/in/noah-ohrner/",
    },
    {
      name: "Ben Adeola",
      qualification:
        "CEO at AnswerGrid (YC S24), ex-Tech Lead at Palantir, ex-Software Engineer at Bloomberg",
      imageUrl: "/judges/bolu.png",
      linkedInUrl: "https://www.linkedin.com/in/ben-adeola/",
    },
    {
      name: "Mostofa Adib Shakib",
      qualification:
        "CEO of Variant Technologies, Venture Investor at GaingelsVC, Princeton Hackathon Winner",
      imageUrl: "/judges/adib.png",
      linkedInUrl: "https://www.linkedin.com/in/adibshakib/",
    },
    {
      name: "Gaurav Puri",
      qualification:
        "Security Engineer at Meta, ex-Senior Data Scientist at Netflix, Columbia University Alum",
      imageUrl: "/judges/gaurav.png",
      linkedInUrl: "https://www.linkedin.com/in/gauravpuri19/",
    },
    {
      name: "Tanveer Salim",
      qualification:
        "Cyber Security Engineer at Intel, 4x Hackathon Winner, Author of Programming Cryptography",
      imageUrl: "/judges/tanveer.png",
      linkedInUrl: "https://www.linkedin.com/in/tanveer-salim-56522820a/",
    },
    {
      name: "Prithiv Dev Devendran",
      qualification:
        "iOS Software Engineer at Intuit, 250K+ downloads on AppStore",
      imageUrl: "/judges/prithiv.png",
      linkedInUrl: "https://www.linkedin.com/in/prithivdev/",
    },
    {
      name: "Jarin Rafa",
      qualification:
        "Head of Design & Operation at CSB, Board of Director at Variant Technologies",
      imageUrl: "/judges/rafa.png",
      linkedInUrl: "https://www.linkedin.com/in/jarinrafa/",
    },
    {
      name: "Nazifa Mouli",
      qualification: "Head of R&D at CSB, CS PhD at Iowa State University",
      imageUrl: "/judges/nazifa.png",
      linkedInUrl: "https://www.linkedin.com/in/nazifamouli/",
    },
    {
      name: "Nafiz Imtiaz",
      qualification: "Software Engineer at Dell, ex-President of RaiderHacks",
      imageUrl: "/judges/nafiz.png",
      linkedInUrl: "https://www.linkedin.com/in/nafizimtiazdev/",
    },
  ];

  return (
    <section className="max-w-screen-xl mx-auto py-12">
      <div data-aos="fade-up" data-aos-duration="1000">
        <SectionTitle title="Judges & Mentors" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 px-8 lg:px-0 py-8">
        {speakers.map((speaker, index) => (
          <JudgeCard key={index} speaker={speaker} />
        ))}
      </div>
    </section>
  );
};

export default JudgeSection;
