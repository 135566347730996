import React, { useState, useEffect } from "react";
import { useAuth } from "../Authentication/AuthContext";
import LoginModal from "../Login/LoginModal";

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(!currentUser);
  }, [currentUser]);

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      {currentUser ? (
        children
      ) : (
        <LoginModal open={modalOpen} handleClose={handleClose} />
      )}
    </>
  );
};

export default PrivateRoute;
