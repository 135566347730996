import React from "react";
import {
  RoadmapContainer,
  Topic,
  VerticalArrow,
  HorizontalArrow,
  RowContainer,
  RoadmapTitle
} from "./RoadmapComponents";

const BackendRoadmap = () => {
  return (
    <RoadmapContainer>
      <RoadmapTitle title="Back-end Roadmap" />

      <Topic
        title="Internet Basics"
        subtopics={["How does the internet work?", "HTTP and HTTPS", "APIs"]}
      />

      <VerticalArrow />

      <RowContainer>
        <Topic
          title="Programming Language"
          subtopics={["Python", "JavaScript (Node.js)", "Java"]}
        />
        <HorizontalArrow />
        <Topic
          title="Version Control"
          subtopics={["Git", "GitHub"]}
        />
      </RowContainer>

      <VerticalArrow />

      <RowContainer>
        <Topic
          title="Databases"
          subtopics={["Relational (SQL)", "NoSQL"]}
        />
        <HorizontalArrow />
        <Topic
          title="APIs"
          subtopics={["REST", "GraphQL"]}
        />
      </RowContainer>

      <VerticalArrow />

      <Topic
        title="Web Frameworks"
        subtopics={["Express.js (Node.js)", "Django (Python)", "Spring Boot (Java)"]}
      />

      <VerticalArrow />

      <RowContainer>
        <Topic
          title="Authentication"
          subtopics={["JWT", "OAuth"]}
        />
        <HorizontalArrow />
        <Topic
          title="Testing"
          subtopics={["Unit Testing", "Integration Testing"]}
        />
      </RowContainer>

      <VerticalArrow />

      <Topic
        title="Deployment"
        subtopics={["Docker", "CI/CD", "Cloud Platforms"]}
      />
    </RoadmapContainer>
  );
};

export default BackendRoadmap;
