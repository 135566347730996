import React from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

const MarkdownRenderer = ({ content }) => {
  const createMarkup = (markdown) => {
    marked.setOptions({
      breaks: true,
      gfm: true,
      highlight: (code, lang) => {
        const language = hljs.getLanguage(lang) ? lang : "plaintext";
        return hljs.highlight(code, { language }).value;
      },
    });

    const rawMarkup = marked(markdown);
    const safeMarkup = DOMPurify.sanitize(rawMarkup);

    return { __html: safeMarkup };
  };

  return (
    <div
      className="markdown-body"
      dangerouslySetInnerHTML={createMarkup(content)}
    />
  );
};

export default MarkdownRenderer;
