import React from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import ComingSoonPage from "../Utils/ComingSoonPage";

const COMING_SOON = "comingsoon";

const VideoScreen = () => {
  const { url } = useParams();
  const videoUrl = decodeURIComponent(url);

  if (videoUrl.trim() === COMING_SOON) {
    return (
      <div className="flex items-center justify-center min-h-screen pt-60 bg-transparent">
        <ComingSoonPage />
      </div>
    );
  } else {
    return (
      <div className="min-h-screen flex flex-col">
        <div className="flex-grow flex justify-center items-center">
          <div className="w-3/5 h-3/5">
            <ReactPlayer url={videoUrl} controls width="120vh" height="70vh" />
          </div>
        </div>
      </div>
    );
  }
};

export default VideoScreen;
