import React from "react";
import { Typography } from "@mui/material";
import {
  RoadmapContainer,
  Topic,
  VerticalArrow,
  HorizontalArrow,
  RowContainer,
} from "./RoadmapComponents";

const FullstackRoadmap = () => {
  return (
    <RoadmapContainer>
      <Typography variant="h4" gutterBottom>
        Full-stack Roadmap
      </Typography>

      <Topic
        title="Internet Fundamentals"
        subtopics={["How the web works", "HTTP/HTTPS", "DNS"]}
      />

      <VerticalArrow />

      <RowContainer>
        <Topic
          title="Front-end Basics"
          subtopics={["HTML", "CSS", "JavaScript"]}
        />
        <HorizontalArrow />
        <Topic
          title="Back-end Basics"
          subtopics={["Server-side language", "Databases", "APIs"]}
        />
      </RowContainer>

      <VerticalArrow />

      <RowContainer>
        <Topic
          title="Front-end Framework"
          subtopics={["React", "Vue.js", "Angular"]}
        />
        <HorizontalArrow />
        <Topic
          title="Back-end Framework"
          subtopics={["Express.js", "Django", "Ruby on Rails"]}
        />
      </RowContainer>

      <VerticalArrow />

      <Topic
        title="Database Management"
        subtopics={[
          "SQL (e.g., PostgreSQL)",
          "NoSQL (e.g., MongoDB)",
          "ORM/ODM",
        ]}
      />

      <VerticalArrow />

      <RowContainer>
        <Topic title="Version Control" subtopics={["Git", "GitHub/GitLab"]} />
        <HorizontalArrow />
        <Topic
          title="DevOps"
          subtopics={["CI/CD", "Docker", "Cloud Platforms"]}
        />
      </RowContainer>

      <VerticalArrow />

      <Topic
        title="Advanced Topics"
        subtopics={["Security", "Performance Optimization", "Scalability"]}
      />
    </RoadmapContainer>
  );
};

export default FullstackRoadmap;
