import React, { useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import Colors from "../../Colors";
import FrontendRoadmap from "./FrontendRoadmap";
import BackendRoadmap from "./BackendRoadmap";
import FullstackRoadmap from "./FullstackRoadmap";
import DSARoadmap from "./DSARoadmap";

const RoadmapContainer = styled(Box)({
  padding: "80px 20px 20px",
  maxWidth: "1200px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Roadmap = () => {
  const [selectedRoadmap, setSelectedRoadmap] = useState("dsa");

  const handleRoadmapChange = (event) => {
    setSelectedRoadmap(event.target.value);
  };

  const getRoadmapComponent = () => {
    switch (selectedRoadmap) {
      case "frontend":
        return <FrontendRoadmap />;
      case "backend":
        return <BackendRoadmap />;
      case "fullstack":
        return <FullstackRoadmap />;
      case "dsa":
        return <DSARoadmap />;
      default:
        return <DSARoadmap />;
    }
  };

  return (
    <RoadmapContainer>
      <Typography
        variant="h2"
        sx={{
          marginBottom: "30px",
          color: Colors.primary,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Roadmaps
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="roadmap"
          name="roadmap"
          value={selectedRoadmap}
          onChange={handleRoadmapChange}
          sx={{ justifyContent: "center" }}
        >
          <FormControlLabel
            value="dsa"
            control={
              <Radio
                sx={{
                  color: Colors.primary,
                  "&.Mui-checked": { color: Colors.primaryVariant },
                }}
              />
            }
            label="DSA"
          />
          <FormControlLabel
            value="frontend"
            control={
              <Radio
                sx={{
                  color: Colors.primary,
                  "&.Mui-checked": { color: Colors.primaryVariant },
                }}
              />
            }
            label="Frontend"
          />
          <FormControlLabel
            value="backend"
            control={
              <Radio
                sx={{
                  color: Colors.primary,
                  "&.Mui-checked": { color: Colors.primaryVariant },
                }}
              />
            }
            label="Backend"
          />
          <FormControlLabel
            value="fullstack"
            control={
              <Radio
                sx={{
                  color: Colors.primary,
                  "&.Mui-checked": { color: Colors.primaryVariant },
                }}
              />
            }
            label="Fullstack"
          />
        </RadioGroup>
      </FormControl>
      <Box sx={{ marginTop: "30px", width: "100%" }}>
        {getRoadmapComponent()}
      </Box>
    </RoadmapContainer>
  );
};

export default Roadmap;
