import React, { useState, useRef, useEffect } from "react";
import { Menu, MenuItem, Button } from "@mui/material";

const BlogNavBar = ({ onCategorySelect }) => {
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navRef = useRef(null);

  const categories = [
    { name: "Home", path: "home" },
    { name: "Higher Study", path: "higherStudy" },
    { name: "Job Seeking", path: "jobSeeking" },
    { name: "Insight", path: "insight" },
    { name: "My Favourite", path: "favourite" },
  ];

  useEffect(() => {
    const updateVisibleCategories = () => {
      const navWidth = navRef.current.offsetWidth;
      let totalWidth = 0;
      const newVisibleCategories = [];

      for (const category of categories) {
        const buttonWidth = category.name.length * 10 + 12;
        if (totalWidth + buttonWidth <= navWidth) {
          newVisibleCategories.push(category);
          totalWidth += buttonWidth;
        } else {
          break;
        }
      }

      setVisibleCategories(newVisibleCategories);
    };

    updateVisibleCategories();
    window.addEventListener("resize", updateVisibleCategories);

    return () => window.removeEventListener("resize", updateVisibleCategories);
  }, [categories]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav
      ref={navRef}
      className="bg-gray-800 p-4 rounded-lg w-full md:w-3/4 lg:w-1/2 mx-auto my-8"
      style={{
        borderRadius: "8px",
        margin: "6rem auto",
        maxWidth: "34%",
        minHeight: "5%",
      }}
    >
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex space-x-4">
          {visibleCategories.map((category) => (
            <button
              key={category.path}
              onClick={() => onCategorySelect(category.path)}
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-lg text-sm font-medium"
              style={{ lineHeight: "normal" }}
            >
              {category.name}
            </button>
          ))}
        </div>

        {visibleCategories.length < categories.length && (
          <div>
            <Button
              aria-controls="category-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-lg text-sm font-medium"
              style={{ lineHeight: "normal" }}
            >
              See More
            </Button>
            <Menu
              id="category-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {categories.slice(visibleCategories.length).map((category) => (
                <MenuItem
                  key={category.path}
                  onClick={() => {
                    onCategorySelect(category.path);
                    handleMenuClose();
                  }}
                >
                  {category.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </div>
    </nav>
  );
};

export default BlogNavBar;
