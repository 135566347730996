import { useEffect } from "react";
import ReactGA from "react-ga";

const userPageTracking = () => {
  useEffect(() => {
    const trackPageView = () => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    };

    // Track the initial page load
    trackPageView();

    window.addEventListener("popstate", trackPageView);

    return () => {
      window.removeEventListener("popstate", trackPageView);
    };
  }, []);
};

export default userPageTracking;
