import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Slider,
  Typography,
  Box,
  Container,
  Paper,
  CircularProgress,
  Fade,
  Card,
  Grid,
  CardContent,
  Link,
  Divider,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: "16px",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 15px 40px rgba(0, 0, 0, 0.15)",
  },
  marginTop: theme.spacing(8),
}));

const Headline = styled(motion.h1)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight: 700,
  marginBottom: theme.spacing(3),
  color: theme.palette.primary?.main || "#1976d2",
  textAlign: "center",
}));

const Blurb = styled(motion.p)(({ theme }) => ({
  fontSize: "1.2rem",
  marginBottom: theme.spacing(6),
  color: theme.palette.text?.secondary || "#666",
  textAlign: "center",
  maxWidth: "800px",
  margin: "0 auto",
}));

const domains = [
  "Web Development",
  "Mobile Development",
  "DevOps",
  "Data Analytics",
  "Data Science",
  "Data Engineering",
  "Machine Learning",
  "Product Management",
  "UX Design",
  "UI Design",
  "Full Stack Development",
  "Cloud Architecture",
  "Cybersecurity",
  "Blockchain Development",
  "AR/VR Development",
  "IoT Development",
  "Game Development",
  "Embedded Systems",
  "Robotics",
  "Natural Language Processing",
];

const technologies = {
  "Web Development": [
    "JavaScript",
    "React",
    "Vue.js",
    "Angular",
    "Node.js",
    "Express",
    "Django",
    "Ruby on Rails",
    "PHP",
    "Laravel",
    "ASP.NET",
    "GraphQL",
    "WebSockets",
  ],
  "Mobile Development": [
    "React Native",
    "Flutter",
    "Swift",
    "Kotlin",
    "Java (Android)",
    "Xamarin",
    "Ionic",
    "PhoneGap",
    "Objective-C",
  ],
  DevOps: [
    "Docker",
    "Kubernetes",
    "Jenkins",
    "AWS",
    "Azure",
    "GCP",
    "Terraform",
    "Ansible",
    "Puppet",
    "Chef",
    "CircleCI",
    "GitLab CI",
    "Prometheus",
    "Grafana",
  ],
  "Data Analytics": [
    "Python",
    "R",
    "SQL",
    "Tableau",
    "Power BI",
    "Excel",
    "SAS",
    "SPSS",
    "Google Analytics",
    "Looker",
    "Sisense",
  ],
  "Data Science": [
    "Python",
    "R",
    "SQL",
    "Machine Learning",
    "Deep Learning",
    "TensorFlow",
    "PyTorch",
    "Scikit-learn",
    "Pandas",
    "NumPy",
    "SciPy",
    "NLTK",
    "Spark MLlib",
  ],
  "Data Engineering": [
    "Python",
    "SQL",
    "Spark",
    "Hadoop",
    "Airflow",
    "Kafka",
    "Hive",
    "Pig",
    "Cassandra",
    "MongoDB",
    "Redis",
    "Elasticsearch",
  ],
  "Machine Learning": [
    "Python",
    "TensorFlow",
    "PyTorch",
    "Scikit-learn",
    "Keras",
    "OpenCV",
    "NLTK",
    "Spark MLlib",
    "XGBoost",
    "LightGBM",
  ],
  "Product Management": [
    "Agile",
    "Scrum",
    "Jira",
    "Product Roadmapping",
    "User Research",
    "A/B Testing",
    "Google Analytics",
    "Mixpanel",
    "Amplitude",
    "Pendo",
  ],
  "UX Design": [
    "Sketch",
    "Figma",
    "Adobe XD",
    "InVision",
    "Axure",
    "UserTesting",
    "Hotjar",
    "Optimal Workshop",
    "Balsamiq",
    "Zeplin",
  ],
  "UI Design": [
    "Adobe Photoshop",
    "Adobe Illustrator",
    "Sketch",
    "Figma",
    "Adobe XD",
    "InVision",
    "Zeplin",
    "Principle",
    "After Effects",
  ],
  "Full Stack Development": [
    "JavaScript",
    "Python",
    "Java",
    "C#",
    "Ruby",
    "PHP",
    "Node.js",
    "React",
    "Angular",
    "Vue.js",
    "Express",
    "Django",
    "Ruby on Rails",
    "ASP.NET",
    "Spring Boot",
  ],
  "Cloud Architecture": [
    "AWS",
    "Azure",
    "GCP",
    "Kubernetes",
    "Docker",
    "Terraform",
    "CloudFormation",
    "Serverless",
    "Microservices",
    "Service Mesh",
  ],
  Cybersecurity: [
    "Network Security",
    "Penetration Testing",
    "Ethical Hacking",
    "Cryptography",
    "SIEM",
    "Firewall",
    "IDS/IPS",
    "OWASP",
    "Nmap",
    "Wireshark",
  ],
  "Blockchain Development": [
    "Solidity",
    "Ethereum",
    "Hyperledger",
    "Smart Contracts",
    "Web3.js",
    "Truffle",
    "Ganache",
    "IPFS",
    "Cryptocurrency",
  ],
  "AR/VR Development": [
    "Unity",
    "Unreal Engine",
    "ARKit",
    "ARCore",
    "Vuforia",
    "OpenVR",
    "WebXR",
    "A-Frame",
    "Three.js",
  ],
  "IoT Development": [
    "Arduino",
    "Raspberry Pi",
    "MQTT",
    "CoAP",
    "Zigbee",
    "LoRaWAN",
    "AWS IoT",
    "Azure IoT",
    "Google Cloud IoT",
  ],
  "Game Development": [
    "Unity",
    "Unreal Engine",
    "C++",
    "C#",
    "JavaScript",
    "Lua",
    "Python",
    "Godot",
    "Phaser",
    "Cocos2d",
  ],
  "Embedded Systems": [
    "C",
    "C++",
    "Assembly",
    "RTOS",
    "ARM",
    "AVR",
    "PIC",
    "FPGA",
    "Verilog",
    "VHDL",
  ],
  Robotics: [
    "ROS",
    "Python",
    "C++",
    "MATLAB",
    "OpenCV",
    "PCL",
    "Gazebo",
    "Arduino",
    "Raspberry Pi",
  ],
  "Natural Language Processing": [
    "Python",
    "NLTK",
    "spaCy",
    "Gensim",
    "Stanford NLP",
    "TensorFlow",
    "PyTorch",
    "Transformers",
    "BERT",
    "GPT",
  ],
};

const personalTraits = [
  "Adaptability",
  "Analytical Thinking",
  "Attention to Detail",
  "Collaboration",
  "Communication",
  "Creativity",
  "Critical Thinking",
  "Curiosity",
  "Decision Making",
  "Emotional Intelligence",
  "Empathy",
  "Flexibility",
  "Growth Mindset",
  "Initiative",
  "Innovation",
  "Leadership",
  "Motivation",
  "Negotiation",
  "Open-mindedness",
  "Optimism",
  "Organization",
  "Patience",
  "Perseverance",
  "Problem Solving",
  "Proactivity",
  "Reliability",
  "Resilience",
  "Self-awareness",
  "Self-motivation",
  "Teamwork",
  "Time Management",
  "Work Ethic",
];

const Employer = () => {
  const [preferences, setPreferences] = useState({
    salary: [0, 200000],
    technologies: [],
    domain: "",
    englishScore: [0, 9],
    problemSolvingScore: [0, 10],
    domainExpertiseScore: [0, 10],
    personalTraits: [],
  });
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shortlistedCandidates, setShortlistedCandidates] = useState([]);

  useEffect(() => {
    if (preferences.domain) {
      setPreferences((prev) => ({ ...prev, technologies: [] }));
    }
  }, [preferences.domain]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPreferences((prev) => ({ ...prev, [name]: value }));
  };

  const handleSalaryChange = (event, newValue) => {
    setPreferences((prev) => ({ ...prev, salary: newValue }));
  };

  const handleRangeChange = (name) => (event, newValue) => {
    setPreferences((prev) => ({ ...prev, [name]: newValue }));
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/1-XSY7RsbERsDa8j0BOYqriJzqRLzq1OV8PtizY59KiU/values/Sheet1!A1:N100?key=AIzaSyDPQx7KooJ0hrwYn3wVxSJoZcvH0TkkGbU`
      );
      const data = await response.json();
      const headers = data.values[0];
      const candidates = data.values.slice(1).map((row) => {
        let candidate = {};
        headers.forEach((header, index) => {
          candidate[header] = row[index];
        });
        return candidate;
      });

      // Filter candidates based on preferences
      const filteredCandidates = candidates.filter((candidate) => {
        const salaryInRange =
          parseInt(candidate["Salary Expectation"]) >= preferences.salary[0] &&
          parseInt(candidate["Salary Expectation"]) <= preferences.salary[1];
        const domainMatch =
          !preferences.domain || candidate["Domain"] === preferences.domain;
        const techMatch =
          preferences.technologies.length === 0 ||
          preferences.technologies.every((tech) =>
            candidate["Skills"].includes(tech)
          );
        const englishScoreMatch =
          parseFloat(candidate["English Score"]) >=
            preferences.englishScore[0] &&
          parseFloat(candidate["English Score"]) <= preferences.englishScore[1];
        const problemSolvingMatch =
          parseFloat(candidate["Problem Solving"]) >=
            preferences.problemSolvingScore[0] &&
          parseFloat(candidate["Problem Solving"]) <=
            preferences.problemSolvingScore[1];
        const domainScoreMatch =
          parseFloat(candidate["Domain Score"]) >=
            preferences.domainExpertiseScore[0] &&
          parseFloat(candidate["Domain Score"]) <=
            preferences.domainExpertiseScore[1];
        const personalTraitsMatch =
          preferences.personalTraits.length === 0 ||
          preferences.personalTraits.every((trait) =>
            candidate["Personality"].includes(trait)
          );

        return (
          salaryInRange &&
          domainMatch &&
          techMatch &&
          englishScoreMatch &&
          problemSolvingMatch &&
          domainScoreMatch &&
          personalTraitsMatch
        );
      });
      setSearchResults(filteredCandidates);
    } catch (error) {
      console.error("Error searching candidates:", error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShortlist = (candidateId) => {
    setShortlistedCandidates((prev) => {
      if (prev.includes(candidateId)) {
        return prev.filter((id) => id !== candidateId);
      } else {
        return [...prev, candidateId];
      }
    });
  };

  const renderCandidates = () => {
    return searchResults.map((candidate, index) => (
      <Card key={index} sx={{ mb: 2, position: "relative" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Salary Expectation:</strong> $
                {candidate["Salary Expectation"]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Availability:</strong> {candidate["Availbility"]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Education:</strong> {candidate["Education"]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Work History:</strong> {candidate["Work History"]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Skills:</strong> {candidate["Skills"]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>English Score:</strong> {candidate["English Score"]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Personality:</strong> {candidate["Personality"]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Problem Solving:</strong> {candidate["Problem Solving"]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Domain:</strong> {candidate["Domain"]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">
                <strong>Domain Score:</strong> {candidate["Domain Score"]}
              </Typography>
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            color="primary"
            startIcon={
              shortlistedCandidates.includes(index) ? (
                <FavoriteIcon />
              ) : (
                <FavoriteBorderIcon />
              )
            }
            onClick={() => handleShortlist(index)}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            {shortlistedCandidates.includes(index)
              ? "Shortlisted"
              : "Shortlist"}
          </Button>
        </CardContent>
      </Card>
    ));
  };

  return (
    <Container maxWidth="md" sx={{ mt: 12, mb: 12 }}>
      <Headline
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Hire Top 1% of Tech Talent
      </Headline>
      <Blurb
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        CSB is an exclusive network of the top 1% of software developers,
        designers, product managers, and project managers in the world. Top SMEs
        in Western countries hire CSB talent for Full-Time remote jobs.
      </Blurb>

      <StyledPaper
        elevation={0}
        component={motion.div}
        whileHover={{ scale: 1.02 }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: 600, mb: 4, textAlign: "center" }}
        >
          Candidate Search
        </Typography>

        <Typography gutterBottom>Salary Range ($)</Typography>
        <Slider
          value={preferences.salary}
          onChange={handleSalaryChange}
          valueLabelDisplay="auto"
          min={0}
          max={200000}
          sx={{ mb: 6 }}
        />

        <TextField
          select
          fullWidth
          margin="normal"
          name="domain"
          label="Domain"
          value={preferences.domain}
          onChange={handleChange}
          sx={{ mb: 4 }}
        >
          {domains.map((domain) => (
            <MenuItem key={domain} value={domain}>
              {domain}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          fullWidth
          margin="normal"
          name="technologies"
          label="Technologies"
          value={preferences.technologies}
          onChange={handleChange}
          SelectProps={{ multiple: true }}
          sx={{ mb: 6 }}
          disabled={!preferences.domain}
        >
          {preferences.domain &&
            technologies[preferences.domain].map((tech) => (
              <MenuItem key={tech} value={tech}>
                {tech}
              </MenuItem>
            ))}
        </TextField>

        <TextField
          select
          fullWidth
          margin="normal"
          name="personalTraits"
          label="Personal Traits"
          value={preferences.personalTraits}
          onChange={handleChange}
          SelectProps={{
            multiple: true,
            renderValue: (selected) => selected.join(", "),
          }}
          sx={{ mb: 4 }}
        >
          {personalTraits.map((trait) => (
            <MenuItem key={trait} value={trait}>
              <Checkbox
                checked={preferences.personalTraits.indexOf(trait) > -1}
              />
              <ListItemText primary={trait} />
            </MenuItem>
          ))}
        </TextField>

        <Typography gutterBottom>English Score Range (0-9)</Typography>
        <Slider
          value={preferences.englishScore}
          onChange={handleRangeChange("englishScore")}
          valueLabelDisplay="auto"
          min={0}
          max={9}
          step={0.5}
          sx={{ mb: 4 }}
        />

        <Typography gutterBottom>Problem Solving Score Range (0-10)</Typography>
        <Slider
          value={preferences.problemSolvingScore}
          onChange={handleRangeChange("problemSolvingScore")}
          valueLabelDisplay="auto"
          min={0}
          max={10}
          sx={{ mb: 4 }}
        />

        <Typography gutterBottom>
          Domain Expertise Score Range (0-10)
        </Typography>
        <Slider
          value={preferences.domainExpertiseScore}
          onChange={handleRangeChange("domainExpertiseScore")}
          valueLabelDisplay="auto"
          min={0}
          max={10}
          sx={{ mb: 4 }}
        />

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disabled={isLoading}
            sx={{
              py: 2,
              px: 6,
              borderRadius: "30px",
              fontWeight: 600,
              fontSize: "1.1rem",
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Search Candidates"
            )}
          </Button>
        </Box>

        <Fade in={searchResults.length > 0}>
          <Box sx={{ mt: 8 }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: 600, mb: 3 }}
            >
              Search Results
            </Typography>
            {renderCandidates()}
          </Box>
        </Fade>
      </StyledPaper>
    </Container>
  );
};

export default Employer;
