import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Paper,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: "16px",
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
}));

const personalTraits = [
  "Adaptability",
  "Analytical Thinking",
  "Attention to Detail",
  "Collaboration",
  "Communication",
  "Creativity",
  "Critical Thinking",
  "Curiosity",
  "Decision Making",
  "Emotional Intelligence",
  "Empathy",
  "Flexibility",
  "Growth Mindset",
  "Initiative",
  "Innovation",
  "Leadership",
  "Motivation",
  "Negotiation",
  "Open-mindedness",
  "Optimism",
  "Organization",
  "Patience",
  "Perseverance",
  "Problem Solving",
  "Proactivity",
  "Reliability",
  "Resilience",
  "Self-awareness",
  "Self-motivation",
  "Teamwork",
  "Time Management",
  "Work Ethic",
];

const questions = [
  {
    question:
      "How do you typically react to unexpected changes in your work environment?",
    options: [
      {
        text: "I easily adapt and find new solutions",
        traits: ["Adaptability", "Flexibility", "Problem Solving"],
      },
      {
        text: "I analyze the situation before responding",
        traits: ["Analytical Thinking", "Critical Thinking"],
      },
      {
        text: "I seek input from others to collaboratively address the change",
        traits: ["Collaboration", "Teamwork", "Communication"],
      },
      {
        text: "I focus on maintaining stability and minimizing disruption",
        traits: ["Reliability", "Organization"],
      },
    ],
  },
  {
    question:
      "When faced with a complex problem, what's your typical approach?",
    options: [
      {
        text: "Break it down into smaller, manageable parts",
        traits: ["Analytical Thinking", "Problem Solving", "Organization"],
      },
      {
        text: "Brainstorm creative solutions",
        traits: ["Creativity", "Innovation", "Critical Thinking"],
      },
      {
        text: "Research and gather more information",
        traits: ["Curiosity", "Analytical Thinking", "Attention to Detail"],
      },
      {
        text: "Collaborate with others to find a solution",
        traits: ["Teamwork", "Communication", "Collaboration"],
      },
    ],
  },
  {
    question: "How do you handle tight deadlines?",
    options: [
      {
        text: "Prioritize tasks and manage time efficiently",
        traits: ["Time Management", "Organization", "Work Ethic"],
      },
      {
        text: "Stay motivated and work extra hours if needed",
        traits: ["Motivation", "Perseverance", "Work Ethic"],
      },
      {
        text: "Delegate tasks and seek help when necessary",
        traits: ["Leadership", "Communication", "Teamwork"],
      },
      {
        text: "Remain calm and focused under pressure",
        traits: ["Emotional Intelligence", "Resilience", "Adaptability"],
      },
    ],
  },
  {
    question: "How do you prefer to communicate with team members?",
    options: [
      {
        text: "Through detailed written reports or emails",
        traits: ["Attention to Detail", "Communication", "Organization"],
      },
      {
        text: "In face-to-face meetings or video calls",
        traits: ["Communication", "Collaboration", "Emotional Intelligence"],
      },
      {
        text: "Using visual aids like charts or diagrams",
        traits: ["Creativity", "Communication", "Analytical Thinking"],
      },
      {
        text: "Through brief, frequent check-ins",
        traits: ["Efficiency", "Communication", "Proactivity"],
      },
    ],
  },
  {
    question: "When learning a new skill, what approach do you typically take?",
    options: [
      {
        text: "Practice repeatedly until I master it",
        traits: ["Perseverance", "Self-motivation", "Work Ethic"],
      },
      {
        text: "Seek out different perspectives and methods",
        traits: ["Open-mindedness", "Curiosity", "Growth Mindset"],
      },
      {
        text: "Apply it to real-world scenarios immediately",
        traits: ["Initiative", "Proactivity", "Problem Solving"],
      },
      {
        text: "Break it down and learn step-by-step",
        traits: ["Analytical Thinking", "Attention to Detail", "Organization"],
      },
    ],
  },
  {
    question: "How do you typically handle conflicts within a team?",
    options: [
      {
        text: "Mediate and find a compromise",
        traits: ["Leadership", "Communication", "Emotional Intelligence"],
      },
      {
        text: "Analyze the root cause and propose solutions",
        traits: ["Analytical Thinking", "Problem Solving", "Critical Thinking"],
      },
      {
        text: "Encourage open dialogue and active listening",
        traits: ["Empathy", "Communication", "Collaboration"],
      },
      {
        text: "Seek guidance from a superior or HR",
        traits: ["Reliability", "Teamwork", "Organization"],
      },
    ],
  },
  {
    question: "When starting a new project, what's your first step?",
    options: [
      {
        text: "Create a detailed plan and timeline",
        traits: ["Organization", "Time Management", "Analytical Thinking"],
      },
      {
        text: "Brainstorm innovative ideas and approaches",
        traits: ["Creativity", "Innovation", "Initiative"],
      },
      {
        text: "Gather the team to discuss objectives and roles",
        traits: ["Leadership", "Collaboration", "Communication"],
      },
      {
        text: "Research similar projects for insights",
        traits: ["Curiosity", "Analytical Thinking", "Attention to Detail"],
      },
    ],
  },
  {
    question: "How do you prefer to receive feedback on your work?",
    options: [
      {
        text: "Detailed written feedback I can review later",
        traits: [
          "Attention to Detail",
          "Analytical Thinking",
          "Self-awareness",
        ],
      },
      {
        text: "Immediate verbal feedback during or after tasks",
        traits: ["Adaptability", "Communication", "Growth Mindset"],
      },
      {
        text: "Regular check-ins with opportunities for discussion",
        traits: ["Communication", "Collaboration", "Self-awareness"],
      },
      {
        text: "Through performance metrics and data",
        traits: [
          "Analytical Thinking",
          "Attention to Detail",
          "Self-motivation",
        ],
      },
    ],
  },
  {
    question: "When faced with a setback, how do you typically respond?",
    options: [
      {
        text: "Analyze what went wrong and create a new plan",
        traits: ["Analytical Thinking", "Resilience", "Problem Solving"],
      },
      {
        text: "Stay positive and look for new opportunities",
        traits: ["Optimism", "Resilience", "Adaptability"],
      },
      {
        text: "Seek support and advice from others",
        traits: ["Collaboration", "Communication", "Growth Mindset"],
      },
      {
        text: "Persist and work harder to overcome the challenge",
        traits: ["Perseverance", "Work Ethic", "Self-motivation"],
      },
    ],
  },
  {
    question: "How do you approach decision-making in uncertain situations?",
    options: [
      {
        text: "Gather as much information as possible before deciding",
        traits: [
          "Analytical Thinking",
          "Attention to Detail",
          "Critical Thinking",
        ],
      },
      {
        text: "Trust my instincts and make quick decisions",
        traits: ["Decision Making", "Initiative", "Self-confidence"],
      },
      {
        text: "Consult with others and consider multiple perspectives",
        traits: ["Collaboration", "Open-mindedness", "Communication"],
      },
      {
        text: "Weigh potential risks and benefits carefully",
        traits: ["Critical Thinking", "Analytical Thinking", "Decision Making"],
      },
    ],
  },
  {
    question: "How do you prefer to organize your work?",
    options: [
      {
        text: "Use detailed to-do lists and schedules",
        traits: ["Organization", "Attention to Detail", "Time Management"],
      },
      {
        text: "Prioritize flexibly based on changing needs",
        traits: ["Adaptability", "Flexibility", "Decision Making"],
      },
      {
        text: "Focus on one task at a time until completion",
        traits: ["Focus", "Work Ethic", "Attention to Detail"],
      },
      {
        text: "Use visual tools like mind maps or Kanban boards",
        traits: ["Creativity", "Organization", "Analytical Thinking"],
      },
    ],
  },
  {
    question: "How do you approach learning from mistakes?",
    options: [
      {
        text: "Analyze the situation to prevent future occurrences",
        traits: ["Analytical Thinking", "Critical Thinking", "Growth Mindset"],
      },
      {
        text: "Share the experience to help others avoid similar mistakes",
        traits: ["Communication", "Teamwork", "Leadership"],
      },
      {
        text: "Focus on solutions and moving forward positively",
        traits: ["Optimism", "Resilience", "Problem Solving"],
      },
      {
        text: "Reflect on personal growth opportunities",
        traits: ["Self-awareness", "Growth Mindset", "Emotional Intelligence"],
      },
    ],
  },
  {
    question: "How do you handle working under pressure?",
    options: [
      {
        text: "Thrive on the challenge and become more focused",
        traits: ["Resilience", "Work Ethic", "Self-motivation"],
      },
      {
        text: "Break tasks into smaller, manageable steps",
        traits: ["Organization", "Problem Solving", "Time Management"],
      },
      {
        text: "Communicate clearly about expectations and timelines",
        traits: ["Communication", "Leadership", "Teamwork"],
      },
      {
        text: "Practice stress-management techniques to stay calm",
        traits: ["Emotional Intelligence", "Adaptability", "Self-awareness"],
      },
    ],
  },
  {
    question: "How do you approach giving feedback to others?",
    options: [
      {
        text: "Provide detailed, constructive criticism",
        traits: ["Attention to Detail", "Communication", "Analytical Thinking"],
      },
      {
        text: "Focus on positive reinforcement and encouragement",
        traits: ["Empathy", "Leadership", "Emotional Intelligence"],
      },
      {
        text: "Use a balanced approach of praise and areas for improvement",
        traits: ["Communication", "Emotional Intelligence", "Leadership"],
      },
      {
        text: "Tailor the feedback style to the individual's preferences",
        traits: ["Adaptability", "Empathy", "Communication"],
      },
    ],
  },
  {
    question: "How do you typically contribute in team meetings?",
    options: [
      {
        text: "Actively share ideas and opinions",
        traits: ["Communication", "Initiative", "Self-confidence"],
      },
      {
        text: "Listen carefully and build on others' ideas",
        traits: ["Collaboration", "Communication", "Analytical Thinking"],
      },
      {
        text: "Take notes and summarize key points",
        traits: ["Attention to Detail", "Organization", "Communication"],
      },
      {
        text: "Facilitate discussions and ensure all voices are heard",
        traits: ["Leadership", "Communication", "Empathy"],
      },
    ],
  },
];

const PersonalityTest = ({ onComplete }) => {
  const [answers, setAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleAnswer = (event) => {
    setAnswers({ ...answers, [currentQuestion]: parseInt(event.target.value) });
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const traits = calculateTraits();
      onComplete(traits);
    }
  };

  const calculateTraits = () => {
    const traitScores = {};
    personalTraits.forEach((trait) => (traitScores[trait] = 0));

    Object.entries(answers).forEach(([questionIndex, answerIndex]) => {
      const selectedTraits =
        questions[questionIndex].options[answerIndex].traits;
      selectedTraits.forEach((trait) => traitScores[trait]++);
    });

    const sortedTraits = Object.entries(traitScores)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([trait, _]) => trait);

    return sortedTraits;
  };

  const progress = ((currentQuestion + 1) / questions.length) * 100;

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          Personality Assessment
        </Typography>
        <LinearProgress variant="determinate" value={progress} sx={{ mb: 4 }} />
        <Box my={4}>
          <Typography variant="h6" gutterBottom>
            Question {currentQuestion + 1} of {questions.length}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {questions[currentQuestion].question}
          </Typography>
          <RadioGroup
            value={answers[currentQuestion] || ""}
            onChange={handleAnswer}
          >
            {questions[currentQuestion].options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={index}
                control={<Radio />}
                label={option.text}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setCurrentQuestion(currentQuestion - 1)}
            disabled={currentQuestion === 0}
          >
            Previous
          </Button>
          <Button
            onClick={handleNext}
            variant="contained"
            color="primary"
            disabled={answers[currentQuestion] === undefined}
          >
            {currentQuestion < questions.length - 1 ? "Next" : "Finish"}
          </Button>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default PersonalityTest;
