import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const ErrorPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <FontAwesomeIcon icon={faExclamationCircle} size="3x" color="red" />
      <h1 className="text-xl font-bold text-gray-800 mt-5">Error Occurred</h1>
      <p className="text-gray-600">
        Sorry, something went wrong. Please try again later.
      </p>
    </div>
  );
};

export default ErrorPage;
