import React from "react";
import LandingMainContent from "./LandingMainContent";

const Landing = () => {
  return (
    <div className="bgImage">
      <LandingMainContent />
    </div>
  );
};

export default Landing;
