import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Paper,
  CircularProgress,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: "16px",
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
}));

const CenteredAudioPlayer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "2rem",
});

const ListeningTest = ({ onComplete }) => {
  const [audioSrc, setAudioSrc] = useState(
    "https://www.ielts-up.com/listening/5.1.mp3"
  );
  const [answers, setAnswers] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutes for this test
  const [testStarted, setTestStarted] = useState(false);

  useEffect(() => {
    let timer;
    if (testStarted && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsPlaying(false);
    }
    return () => clearInterval(timer);
  }, [testStarted, timeLeft]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    // In a real scenario, you would send the answers to your backend for grading
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const score = 1;
    setIsSubmitting(false);
    onComplete(score);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleAudioPlay = () => {
    setIsPlaying(true);
    if (!testStarted) {
      setTestStarted(true);
    }
  };

  const handleAudioPause = () => {
    setIsPlaying(false);
  };

  const isSubmitDisabled = () => {
    const answeredQuestions = Object.keys(answers).length;
    const requiredQuestions = 10; // Total number of questions
    return (
      isSubmitting ||
      answeredQuestions < requiredQuestions ||
      (testStarted && timeLeft > 0)
    );
  };

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom align="center">
          IELTS Listening Test
        </Typography>
        <CenteredAudioPlayer>
          <Box>
            <audio
              src={audioSrc}
              controls
              onPlay={handleAudioPlay}
              onPause={handleAudioPause}
            />
            <Typography variant="body2" align="center" mt={1}>
              Time left: {formatTime(timeLeft)}
            </Typography>
          </Box>
        </CenteredAudioPlayer>
        <Box my={4}>
          <Typography variant="h6" gutterBottom>
            Questions 1-5
          </Typography>
          <Typography variant="body1" gutterBottom>
            Complete the information below. Write NO MORE THAN ONE WORD OR A
            NUMBER for each answer.
          </Typography>
          <TextField
            fullWidth
            label="1. Street №"
            variant="outlined"
            margin="normal"
            value={answers[1] || ""}
            onChange={(e) => handleAnswerChange(1, e.target.value)}
          />
          <TextField
            fullWidth
            label="2. Included utilities (besides Gas, heat, water, and phone)"
            variant="outlined"
            margin="normal"
            value={answers[2] || ""}
            onChange={(e) => handleAnswerChange(2, e.target.value)}
          />
          <TextField
            fullWidth
            label="3. Not included utilities"
            variant="outlined"
            margin="normal"
            value={answers[3] || ""}
            onChange={(e) => handleAnswerChange(3, e.target.value)}
          />
          <TextField
            fullWidth
            label="4. Public transport (besides Underground)"
            variant="outlined"
            margin="normal"
            value={answers[4] || ""}
            onChange={(e) => handleAnswerChange(4, e.target.value)}
          />
          <TextField
            fullWidth
            label="5. Tenant's last name"
            variant="outlined"
            margin="normal"
            value={answers[5] || ""}
            onChange={(e) => handleAnswerChange(5, e.target.value)}
          />

          <Typography variant="h6" gutterBottom mt={4}>
            Questions 6-8
          </Typography>
          <Typography variant="body1" gutterBottom>
            Choose THREE letters, A-F. Which THREE things should the caller
            bring to the meeting?
          </Typography>
          {["A", "B", "C", "D", "E", "F"].map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Radio
                  checked={
                    answers[6]?.includes(option) ||
                    answers[7]?.includes(option) ||
                    answers[8]?.includes(option)
                  }
                  onChange={() => {
                    const currentAnswers = [
                      answers[6],
                      answers[7],
                      answers[8],
                    ].filter(Boolean);
                    if (currentAnswers.includes(option)) {
                      const newAnswers = currentAnswers.filter(
                        (a) => a !== option
                      );
                      setAnswers((prev) => ({
                        ...prev,
                        6: newAnswers[0],
                        7: newAnswers[1],
                        8: newAnswers[2],
                      }));
                    } else if (currentAnswers.length < 3) {
                      const newAnswers = [...currentAnswers, option];
                      setAnswers((prev) => ({
                        ...prev,
                        6: newAnswers[0],
                        7: newAnswers[1],
                        8: newAnswers[2],
                      }));
                    }
                  }}
                />
              }
              label={option}
            />
          ))}

          <Typography variant="h6" gutterBottom mt={4}>
            Questions 9-10
          </Typography>
          <Typography variant="body1" gutterBottom>
            Choose the correct letters, A, B, or C.
          </Typography>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <Typography variant="subtitle1">
              9. What time do the caller and apartment manager decide to meet?
            </Typography>
            <RadioGroup
              value={answers[9] || ""}
              onChange={(e) => handleAnswerChange(9, e.target.value)}
            >
              <FormControlLabel value="A" control={<Radio />} label="5:30 PM" />
              <FormControlLabel value="B" control={<Radio />} label="6:00 PM" />
              <FormControlLabel value="C" control={<Radio />} label="6:30 PM" />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" margin="normal" fullWidth>
            <Typography variant="subtitle1">
              10. Where do they decide to meet?
            </Typography>
            <RadioGroup
              value={answers[10] || ""}
              onChange={(e) => handleAnswerChange(10, e.target.value)}
            >
              <FormControlLabel
                value="A"
                control={<Radio />}
                label="Near the manager's apartment"
              />
              <FormControlLabel
                value="B"
                control={<Radio />}
                label="At the caller's future apartment"
              />
              <FormControlLabel
                value="C"
                control={<Radio />}
                label="At the office"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitDisabled()}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default ListeningTest;
