import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import AceEditor from "react-ace";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "90vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const EditorContainer = styled(Box)({
  display: "flex",
  flexGrow: 1,
  overflow: "hidden",
});

const ProblemDescription = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: theme.spacing(2),
  overflowY: "auto",
  borderRight: "1px solid #e0e0e0", // Light grey border
}));

const CodeEditor = styled(Box)({
  width: "50%",
  display: "flex",
  flexDirection: "column",
});

const OutputBox = styled(Box)({
  height: "30%",
  overflowY: "auto",
  padding: "16px",
  backgroundColor: "#f5f5f5", // Light grey background
  borderTop: "1px solid #e0e0e0", // Light grey border
});

const CompletedBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  backgroundColor: "#e8f5e9", // Light green background
  color: "#2e7d32", // Dark green text
  fontWeight: "bold",
});

const problems = {
  EASY: {
    title: "1. Two Sum",
    difficulty: "Easy",
    description:
      "Given an array of integers nums and an integer target, return indices of the two numbers such that they add up to target. You may assume that each input would have exactly one solution, and you may not use the same element twice. You can return the answer in any order.",
    examples: [
      {
        input: "nums = [2,7,11,15], target = 9",
        output: "[0,1]",
        explanation: "Because nums[0] + nums[1] == 9, we return [0, 1].",
      },
      { input: "nums = [3,2,4], target = 6", output: "[1,2]" },
      { input: "nums = [3,3], target = 6", output: "[0,1]" },
    ],
    constraints: [
      "2 <= nums.length <= 10^4",
      "-10^9 <= nums[i] <= 10^9",
      "-10^9 <= target <= 10^9",
      "Only one valid answer exists.",
    ],
    starterCode: {
      python:
        "class Solution:\n    def twoSum(self, nums: List[int], target: int) -> List[int]:\n        ",
      c_cpp:
        "class Solution {\npublic:\n    vector<int> twoSum(vector<int>& nums, int target) {\n        \n    }\n};",
      java: "class Solution {\n    public int[] twoSum(int[] nums, int target) {\n        \n    }\n}",
      javascript:
        "/**\n * @param {number[]} nums\n * @param {number} target\n * @return {number[]}\n */\nvar twoSum = function(nums, target) {\n    \n};",
    },
  },
  MEDIUM: {
    title: "5. Longest Palindromic Substring",
    difficulty: "Medium",
    description:
      "Given a string s, return the longest palindromic substring in s.",
    examples: [
      {
        input: 's = "babad"',
        output: '"bab"',
        explanation: '"aba" is also a valid answer.',
      },
      { input: 's = "cbbd"', output: '"bb"' },
    ],
    constraints: [
      "1 <= s.length <= 1000",
      "s consist of only digits and English letters.",
    ],
    starterCode: {
      python:
        "class Solution:\n    def longestPalindrome(self, s: str) -> str:\n        ",
      c_cpp:
        "class Solution {\npublic:\n    string longestPalindrome(string s) {\n        \n    }\n};",
      java: "class Solution {\n    public String longestPalindrome(String s) {\n        \n    }\n}",
      javascript:
        "/**\n * @param {string} s\n * @return {string}\n */\nvar longestPalindrome = function(s) {\n    \n};",
    },
  },
};

const ProblemSolvingTest = ({ onComplete, difficulty = "EASY" }) => {
  const [code, setCode] = useState("");
  const [output, setOutput] = useState("");
  const [language, setLanguage] = useState("python");
  const [question, setQuestion] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentProblemIndex, setCurrentProblemIndex] = useState(0);
  const problemKeys = Object.keys(problems);

  useEffect(() => {
    setQuestion(problems[problemKeys[currentProblemIndex]]);
    setCode(problems[problemKeys[currentProblemIndex]].starterCode[language]);
    setIsSubmitted(false);
  }, [currentProblemIndex, language]);

  const handleRunCode = async () => {
    setOutput("Running code...");
    // Implement code execution logic here
  };

  const handleSubmit = async () => {
    // Implement submission logic here
    setIsSubmitted(true);
    if (currentProblemIndex < problemKeys.length - 1) {
      setCurrentProblemIndex(currentProblemIndex + 1);
    } else {
      onComplete(currentProblemIndex);
    }
  };

  const handleLanguageChange = (event, newValue) => {
    setLanguage(newValue);
    setCode(question.starterCode[newValue]);
  };

  if (!question) {
    return <Typography>Loading question...</Typography>;
  }

  return (
    <StyledPaper elevation={3}>
      <Box p={2}>
        <Typography variant="h5" gutterBottom>
          {question.title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Difficulty: {question.difficulty}
        </Typography>
      </Box>
      <Divider />
      <EditorContainer>
        <ProblemDescription>
          <Typography variant="body1" paragraph>
            {question.description}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Examples:
          </Typography>
          {question.examples.map((example, index) => (
            <Box key={index} mb={2}>
              <Typography variant="body2">Input: {example.input}</Typography>
              <Typography variant="body2">Output: {example.output}</Typography>
              {example.explanation && (
                <Typography variant="body2">
                  Explanation: {example.explanation}
                </Typography>
              )}
            </Box>
          ))}
          <Typography variant="h6" gutterBottom>
            Constraints:
          </Typography>
          <ul>
            {question.constraints.map((constraint, index) => (
              <li key={index}>
                <Typography variant="body2">{constraint}</Typography>
              </li>
            ))}
          </ul>
        </ProblemDescription>
        <CodeEditor>
          <Tabs
            value={language}
            onChange={handleLanguageChange}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab label="Python" value="python" />
            <Tab label="C++" value="c_cpp" />
            <Tab label="Java" value="java" />
            <Tab label="JavaScript" value="javascript" />
          </Tabs>
          <AceEditor
            mode={language}
            theme="github"
            onChange={setCode}
            name="code_editor"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 4,
            }}
            style={{ width: "100%", height: "70%" }}
            value={code}
          />
          {isSubmitted && currentProblemIndex === problemKeys.length - 1 ? (
            <CompletedBox>
              <CheckCircleIcon sx={{ mr: 1 }} />
              All Problems Completed
            </CompletedBox>
          ) : (
            <OutputBox>
              <Typography variant="h6" gutterBottom>
                Output:
              </Typography>
              <pre>{output}</pre>
            </OutputBox>
          )}
        </CodeEditor>
      </EditorContainer>
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={handleRunCode}
          sx={{ mr: 1 }}
          disabled={
            isSubmitted && currentProblemIndex === problemKeys.length - 1
          }
        >
          Run Code
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            isSubmitted && currentProblemIndex === problemKeys.length - 1
          }
        >
          {currentProblemIndex < problemKeys.length - 1
            ? "Submit and Next"
            : "Submit"}
        </Button>
      </Box>
    </StyledPaper>
  );
};

export default ProblemSolvingTest;
