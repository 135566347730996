import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const RoadmapContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
});

export const TopicBox = styled(Box)({
  backgroundColor: "#FFD700",
  padding: "7px",
  margin: "7px",
  borderRadius: "5px",
  cursor: "pointer",
  width: "20%",
  "&:hover": {
    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
  },
});

export const SubTopicBox = styled(Box)({
  backgroundColor: "#FFF8DC",
  padding: "6px",
  margin: "4px",
  borderRadius: "3px",
  fontSize: "0.8em",
});

export const ArrowContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px 0",
});

export const RowContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

export const Topic = ({ title, subtopics }) => (
  <TopicBox>
    <Typography variant="h8">{title}</Typography>
    {subtopics.map((subtopic, index) => (
      <SubTopicBox key={index}>{subtopic}</SubTopicBox>
    ))}
  </TopicBox>
);

export const VerticalArrow = () => (
  <ArrowContainer>
    <ArrowDownwardIcon />
  </ArrowContainer>
);

export const HorizontalArrow = () => (
  <ArrowContainer>
    <ArrowForwardIcon />
  </ArrowContainer>
);

export const RoadmapTitle = ({ title }) => (
  <Typography variant="h4" gutterBottom>
    {title}
  </Typography>
);
