import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { useAuth } from "../Authentication/AuthContext";
import "../CustomStyling/Navbar.css";
import LoginModal from "../Login/LoginModal";

const Navbar = () => {
  const { currentUser, signOutUser } = useAuth();
  const [mobileNav, setMobileNav] = useState(false);
  const [header, setHeader] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const handleLogout = async () => {
    try {
      await signOutUser();
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const menu = [
    // { id: 1, text: "Services", to: "/courses" },
    // { id: 2, text: "AI Interviewer", to: "/interview" },
    // { id: 3, text: "Jarvis", to: "/jarvis" },
    // { id: 4, text: "Resume", to: "/resume" },
    // { id: 3, text: "Employer", to: "/employer" },
    // { id: 4, text: "Employee", to: "/employee" },
    { id: 4, text: "Team", to: "/team" },
    { id: 6, text: "Hackathon", to: "/hackathon" },
  ];

  if (currentUser) {
    menu.push({
      id: 1,
      text: "Blog",
      to: "/blog",
    });
    menu.push({ id: 5, text: "Roadmap", to: "/roadmap" });
    // menu.push({
    //   id: 7,
    //   text: "Candidate Dashboard",
    //   to: "/candidate-dashboard",
    // });
  }

  const handleClick = () => {
    setMobileNav(!mobileNav);
  };

  const changeHeader = () => {
    setHeader(window.scrollY >= 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeHeader);
    return () => {
      window.removeEventListener("scroll", changeHeader);
    };
  }, []);

  return (
    <header
      className={`fixed w-full z-10 ${
        header ? "bg-white shadow-md" : "bg-transparent"
      }`}
    >
      <nav className="max-w-screen-xl mx-auto px-4 flex items-center justify-between h-16">
        <div className="flex items-center">
          <Link to="/">
            <img src="./logo.png" alt="logo" className="w-12 h-12" />
          </Link>
          <h1 className="hidden md:block text-xl font-semibold text-csb-500 ml-4">
            CSB
          </h1>
          <h1 className="block md:hidden text-xl font-semibold text-csb-500 ml-4">
            CSB
          </h1>
        </div>
        <div className="hidden md:flex items-center space-x-4">
          {menu.map((item) => (
            <Link key={item.id} to={item.to} className="text-gray-600 text-sm">
              {item.text}
            </Link>
          ))}
          {currentUser ? (
            <button
              className="bg-csb-500 px-4 py-2 text-sm text-white rounded-full hover:bg-csb-600"
              onClick={handleLogout}
            >
              Log Out
            </button>
          ) : (
            <button
              className="bg-csb-500 px-4 py-2 text-sm text-white rounded-full hover:bg-csb-600"
              onClick={handleOpenLoginModal}
            >
              Login
            </button>
          )}
        </div>
        <div className="md:hidden flex items-center">
          <button onClick={handleClick} aria-label="Open menu">
            <HiMenuAlt3 className="w-10 h-10 text-gray-700" />
          </button>
        </div>
      </nav>
      {mobileNav && (
        <div className="md:hidden bg-white shadow-lg rounded-lg border border-gray-300 py-2 px-4">
          <ul>
            {menu.map((item) => (
              <Link
                key={item.id}
                to={item.to}
                className="block text-gray-600 text-sm py-2"
                onClick={handleClick}
              >
                {item.text}
              </Link>
            ))}
          </ul>
          <div className="mt-4">
            {currentUser ? (
              <button
                className="bg-csb-500 px-4 py-2 w-full text-sm text-white rounded-full hover:bg-csb-600"
                onClick={handleLogout}
              >
                Log Out
              </button>
            ) : (
              <button
                className="bg-csb-500 px-4 py-2 w-full text-sm text-white rounded-full hover:bg-csb-600"
                onClick={handleOpenLoginModal}
              >
                Login
              </button>
            )}
          </div>
        </div>
      )}
      <LoginModal open={openLoginModal} handleClose={handleCloseLoginModal} />
    </header>
  );
};

export default Navbar;
