import React, { useState, useEffect, useRef } from "react";
import SectionTitle from "../LandingPage/components/SectionTitle";

function UniversityPartnershipsCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  const logos = [
    { src: "../../partnerships/buet.png" },
    { src: "../../partnerships/iut.png" },
    { src: "../../partnerships/answerGrid.png" },
    { src: "../../partnerships/unicorn.png" },
    { src: "../../partnerships/brac.png" },
    { src: "../../partnerships/daffodil.png" },
    { src: "../../partnerships/variant.png" },
    { src: "../../partnerships/green.png" },
    { src: "../../partnerships/iiuc.png" },
    { src: "../../partnerships/comilla.png" },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      if (carouselRef.current) {
        if (currentIndex === logos.length - 3) {
          carouselRef.current.scrollLeft = 0;
        } else {
          carouselRef.current.scrollLeft +=
            carouselRef.current.offsetWidth / 3 + 12;
        }
        setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
      }
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [logos.length, currentIndex]);

  return (
    <section className="max-w-screen-xl mx-auto py-16">
      <SectionTitle title="Our Partners" />

      <div className="relative w-full overflow-hidden py-16" ref={carouselRef}>
        <div
          className="flex transition-transform duration-500 ease-in-out whitespace-nowrap"
          style={{ width: `${(logos.length * 100) / 3}%` }}
        >
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={`Partner Logo ${index + 1}`}
              className="bg-transparent h-20 w-1/3 object-contain mx-3"
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default UniversityPartnershipsCarousel;
