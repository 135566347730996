import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import {
  Box,
  Typography,
  Modal,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";

const initialNodes = [
  {
    id: "1",
    position: { x: 400, y: 0 },
    data: {
      label: "Arrays & Hashing",
      problems: [
        "Two Sum",
        "Valid Anagram",
        "Group Anagrams",
        "Top K Frequent Elements",
        "Product of Array Except Self",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "2",
    position: { x: 200, y: 100 },
    data: {
      label: "Two Pointers",
      problems: [
        "Valid Palindrome",
        "3Sum",
        "Container With Most Water",
        "Trapping Rain Water",
        "Remove Nth Node From End of List",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "3",
    position: { x: 600, y: 100 },
    data: {
      label: "Stack",
      problems: [
        "Valid Parentheses",
        "Min Stack",
        "Evaluate Reverse Polish Notation",
        "Daily Temperatures",
        "Largest Rectangle in Histogram",
      ],
      difficulty: "easy",
      completed: false,
    },
  },
  {
    id: "4",
    position: { x: 0, y: 200 },
    data: {
      label: "Binary Search",
      problems: [
        "Search Insert Position",
        "Find Minimum in Rotated Sorted Array",
        "Search in Rotated Sorted Array",
        "Time Based Key-Value Store",
        "Median of Two Sorted Arrays",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "5",
    position: { x: 200, y: 200 },
    data: {
      label: "Sliding Window",
      problems: [
        "Best Time to Buy and Sell Stock",
        "Longest Substring Without Repeating Characters",
        "Longest Repeating Character Replacement",
        "Minimum Window Substring",
        "Sliding Window Maximum",
      ],
      difficulty: "hard",
      completed: false,
    },
  },
  {
    id: "6",
    position: { x: 400, y: 200 },
    data: {
      label: "Linked List",
      problems: [
        "Reverse Linked List",
        "Merge Two Sorted Lists",
        "Reorder List",
        "Remove Nth Node From End of List",
        "Linked List Cycle",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "7",
    position: { x: 400, y: 300 },
    data: {
      label: "Trees",
      problems: [
        "Invert Binary Tree",
        "Maximum Depth of Binary Tree",
        "Same Tree",
        "Subtree of Another Tree",
        "Binary Tree Level Order Traversal",
      ],
      difficulty: "easy",
      completed: false,
    },
  },
  {
    id: "8",
    position: { x: 200, y: 400 },
    data: {
      label: "Tries",
      problems: [
        "Implement Trie (Prefix Tree)",
        "Design Add and Search Words Data Structure",
        "Word Search II",
        "Palindrome Pairs",
        "Design Search Autocomplete System",
      ],
      difficulty: "hard",
      completed: false,
    },
  },
  {
    id: "9",
    position: { x: 600, y: 400 },
    data: {
      label: "Backtracking",
      problems: [
        "Subsets",
        "Combination Sum",
        "Permutations",
        "Palindrome Partitioning",
        "N-Queens",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "10",
    position: { x: 400, y: 500 },
    data: {
      label: "Heap / Priority Queue",
      problems: [
        "Kth Largest Element in an Array",
        "Top K Frequent Elements",
        "Find Median from Data Stream",
        "Merge K Sorted Lists",
        "Task Scheduler",
      ],
      difficulty: "hard",
      completed: false,
    },
  },
  {
    id: "11",
    position: { x: 200, y: 600 },
    data: {
      label: "Intervals",
      problems: [
        "Insert Interval",
        "Merge Intervals",
        "Non-overlapping Intervals",
        "Meeting Rooms II",
        "Minimum Number of Arrows to Burst Balloons",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "12",
    position: { x: 400, y: 600 },
    data: {
      label: "Greedy",
      problems: [
        "Maximum Subarray",
        "Jump Game",
        "Gas Station",
        "Hand of Straights",
        "Merge Triplets to Form Target Triplet",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "13",
    position: { x: 600, y: 600 },
    data: {
      label: "Advanced Graphs",
      problems: [
        "Alien Dictionary",
        "Reconstruct Itinerary",
        "Min Cost to Connect All Points",
        "Network Delay Time",
        "Swim in Rising Water",
      ],
      difficulty: "hard",
      completed: false,
    },
  },
  {
    id: "14",
    position: { x: 800, y: 500 },
    data: {
      label: "Graphs",
      problems: [
        "Number of Islands",
        "Clone Graph",
        "Pacific Atlantic Water Flow",
        "Course Schedule",
        "Word Ladder",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "15",
    position: { x: 1000, y: 500 },
    data: {
      label: "1-D DP",
      problems: [
        "Climbing Stairs",
        "House Robber",
        "Longest Increasing Subsequence",
        "Word Break",
        "Coin Change",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
  {
    id: "16",
    position: { x: 800, y: 600 },
    data: {
      label: "2-D DP",
      problems: [
        "Unique Paths",
        "Longest Common Subsequence",
        "Best Time to Buy and Sell Stock with Cooldown",
        "Interleaving String",
        "Burst Balloons",
      ],
      difficulty: "hard",
      completed: false,
    },
  },
  {
    id: "17",
    position: { x: 1000, y: 600 },
    data: {
      label: "Bit Manipulation",
      problems: [
        "Single Number",
        "Number of 1 Bits",
        "Counting Bits",
        "Reverse Bits",
        "Missing Number",
      ],
      difficulty: "easy",
      completed: false,
    },
  },
  {
    id: "18",
    position: { x: 800, y: 700 },
    data: {
      label: "Math & Geometry",
      problems: [
        "Rotate Image",
        "Spiral Matrix",
        "Set Matrix Zeroes",
        "Happy Number",
        "Plus One",
      ],
      difficulty: "medium",
      completed: false,
    },
  },
];

const initialEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e1-3",
    source: "1",
    target: "3",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e2-4",
    source: "2",
    target: "4",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e2-5",
    source: "2",
    target: "5",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e3-6",
    source: "3",
    target: "6",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e4-7",
    source: "4",
    target: "7",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e5-7",
    source: "5",
    target: "7",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e6-7",
    source: "6",
    target: "7",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e7-8",
    source: "7",
    target: "8",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e7-9",
    source: "7",
    target: "9",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e7-10",
    source: "7",
    target: "10",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e10-11",
    source: "10",
    target: "11",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e10-12",
    source: "10",
    target: "12",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e10-13",
    source: "10",
    target: "13",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e10-14",
    source: "10",
    target: "14",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e14-15",
    source: "14",
    target: "15",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e14-16",
    source: "14",
    target: "16",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e15-17",
    source: "15",
    target: "17",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
  {
    id: "e16-18",
    source: "16",
    target: "18",
    animated: true,
    markerEnd: { type: MarkerType.ArrowClosed },
  },
];

const difficultyColors = {
  easy: "#4CAF50",
  medium: "#FFC107",
  hard: "#F44336",
};

const StyledModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalContent = styled(Box)(({ theme, difficulty }) => ({
  background:
    difficulty === "easy"
      ? "#4CAF50"
      : difficulty === "medium"
      ? "#FFC107"
      : "#F44336",
  padding: "20px",
  borderRadius: "5px",
  maxWidth: "90%",
  maxHeight: "90vh",
  overflow: "auto",
  color: "white",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "500px",
  },
}));

const Flow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [selectedNode, setSelectedNode] = useState(null);
  const reactFlowInstance = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onInit = useCallback((instance) => {
    reactFlowInstance.current = instance;
    instance.fitView({ padding: 0.2 });
  }, []);

  useEffect(() => {
    console.log("Selected node changed:", selectedNode);
  }, [selectedNode]);

  const handleCloseModal = () => {
    setSelectedNode(null);
  };

  const handleCompleteNode = useCallback(
    (id) => {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === id
            ? {
                ...node,
                data: { ...node.data, completed: !node.data.completed },
              }
            : node
        )
      );
      setSelectedNode((prev) =>
        prev
          ? { ...prev, data: { ...prev.data, completed: !prev.data.completed } }
          : null
      );
    },
    [setNodes]
  );

  const progress = useMemo(() => {
    const completedProblems = nodes.reduce((sum, node) => {
      return sum + (node.data.completed ? node.data.problems.length : 0);
    }, 0);
    const totalProblems = nodes.reduce((sum, node) => {
      return sum + node.data.problems.length;
    }, 0);
    return (completedProblems / totalProblems) * 100;
  }, [nodes]);

  const nodeColor = (node) => {
    return node.data.completed
      ? "#4CAF50"
      : difficultyColors[node.data.difficulty];
  };

  const openLeetCodeProblem = (problem) => {
    window.open(
      `https://leetcode.com/problems/${problem
        .toLowerCase()
        .replace(/\s+/g, "-")}/`,
      "_blank"
    );
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onInit={onInit}
            fitView
            minZoom={0.1}
            maxZoom={2}
            defaultEdgeOptions={{
              style: { stroke: "#000", strokeWidth: 2 },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                color: "#000",
              },
            }}
            onNodeClick={(event, node) => {
              setSelectedNode(node);
            }}
            zoomOnDoubleClick={false}
            proOptions={{ hideAttribution: true }}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={true}
            nodeColor={nodeColor}
          >
            <Background color="#aaa" gap={16} />
          </ReactFlow>
        </Box>
      </Box>
      <StyledModal open={Boolean(selectedNode)} onClose={handleCloseModal}>
        <ModalContent difficulty={selectedNode?.data.difficulty}>
          {selectedNode && (
            <>
              <Typography variant="h6" component="h2" gutterBottom>
                {selectedNode.data.label}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Top 5 LeetCode problems for this topic:
              </Typography>
              <List>
                {selectedNode.data.problems.map((problem, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => openLeetCodeProblem(problem)}
                  >
                    <ListItemText primary={problem} />
                  </ListItem>
                ))}
              </List>
              <button onClick={() => handleCompleteNode(selectedNode.id)}>
                {selectedNode.data.completed
                  ? "Mark as Incomplete"
                  : "Mark as Complete"}
              </button>
            </>
          )}
        </ModalContent>
      </StyledModal>
    </>
  );
};

const DSARoadmap = () => {
  return (
    <ReactFlowProvider>
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Flow />
      </Box>
    </ReactFlowProvider>
  );
};

export default DSARoadmap;
