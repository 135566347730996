import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

function AsSeenOnSection() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const logos = [
    {
      src: "../../newspapers/bpost.png",
      link: "https://bangladeshpost.net/posts/hackcsb-ignites-innovation-among-bangladesh-s-tech-students-144881",
    },
    {
      src: "../../newspapers/financialE.png",
      link: "https://thefinancialexpress.com.bd/sci-tech/how-a-bangladeshi-hackathon-is-inspiring-a-generation",
    },
    {
      src: "../../newspapers/dhaka.png",
      link: "https://www.dhakatribune.com/bangladesh/education/357156/bangladesh%E2%80%99s-tech-sector-energized-by",
    },
    {
      src: "../../newspapers/hackernoon.png",
      link: "https://hackernoon.com/from-bangladesh-to-texas-shakib-and-the-power-of-hackathons",
    },
    {
      src: "../../newspapers/manab.png",
      link: "https://mzamin.com/news.php?news=103769&fbclid=IwAR14ocjEhgN_d-e0FeUu2ldmzbZAs2Z7XQxMiyrnsu9QITl6VtRlToXtXJ8_aem_AXmPyE7ssNDVVh671wbOlLaLP_t8Tn7_ELeENt8RplMbjbB8Yolt_DyB_AWhBscTJMd7PtDCOU5hw4xR2FcqV7os",
    },
    {
      src: "../../newspapers/usa.png",
      link: "https://www.usatoday.com/story/special/contributor-content/2024/06/11/adibs-path-from-steam-trader-to-silicon-valley/74061707007/",
    },
    {
      src: "../../newspapers/microsoft.png",
      link: "https://www.msn.com/en-us/news/other/how-adib-is-transforming-education-and-media/ar-BB1nTnxZ?disableErrorRedirect=true&infiniteContentCount=0&apiversion=v2&noservercache=1&domshim=1&renderwebcomponents=1&wcseo=1&batchservertelemetry=1&noservertelemetry=1",
    },
    {
      src: "../../newspapers/canvas.png",
      link: "https://canvasrebel.com/meet-mostofa-shakib/",
    },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [logos.length]);

  const getVisibleLogos = () => {
    return [
      logos[currentIndex],
      logos[(currentIndex + 1) % logos.length],
      logos[(currentIndex + 2) % logos.length],
    ];
  };

  return (
    <Box className="as-seen-on bg-white py-4">
      <h2 className="text-4xl sm:text-5xl md:text-6xl leading-tight font-extrabold text-gray-900 text-center mb-8 font-display">
        As Seen On...
      </h2>
      <div className="flex items-center justify-center gap-8 overflow-hidden py-2">
        {getVisibleLogos().map((logo, index) => (
          <a
            key={index}
            href={logo.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-[280px] h-[420px]"
          >
            <img
              src={logo.src}
              alt={`Logo ${currentIndex + index + 1}`}
              className="bg-transparent transition-opacity duration-500 ease-in-out object-contain max-w-full max-h-full"
            />
          </a>
        ))}
      </div>
    </Box>
  );
}

export default AsSeenOnSection;
