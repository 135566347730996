import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  TextField,
  Paper,
  Fade,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: "16px",
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
}));

const AnimatedTypography = motion(Typography);

const MINIMUM_CHARACTERS = 500;
const writingTopics = [
  "Discuss the impact of social media on modern society.",
  "Describe the most significant technological advancement in the last decade and its effects.",
  "Explain the importance of environmental conservation and suggest ways to promote it.",
  "Discuss the benefits and drawbacks of remote work and learning.",
  "Analyze the role of education in personal and societal development.",
];

const WritingEnglishTest = ({ onComplete }) => {
  const [topic, setTopic] = useState("");
  const [essay, setEssay] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [score, setScore] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(0);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [testStarted, setTestStarted] = useState(false);

  useEffect(() => {
    // Select a random topic
    const randomTopic =
      writingTopics[Math.floor(Math.random() * writingTopics.length)];
    setTopic(randomTopic);
  }, []);

  useEffect(() => {
    let interval;
    if (isTestStarted && !isSubmitting) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTestStarted, isSubmitting]);

  const handleStartTest = () => {
    if (testStarted) return; // Prevent restarting the test
    setTestStarted(true);
    setIsTestStarted(true);
  };

  const handleEssayChange = (event) => {
    if (!testStarted) return; // Prevent editing if test hasn't started
    const text = event.target.value;
    setEssay(text);
    setCharacterCount(text.length);
    setError("");
  };

  const handleSubmit = async () => {
    if (characterCount < MINIMUM_CHARACTERS) {
      setError(
        `Your essay must be at least ${MINIMUM_CHARACTERS} characters long.`
      );
      return;
    }

    setIsSubmitting(true);
    try {
      // const response = await fetch("/grade-writing-test", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${process.env.REACT_APP_CSB_SERVER_API_SECRET}`,
      //   },
      //   body: JSON.stringify({ topic, essay }),
      // });

      // if (!response.ok) {
      //   throw new Error("Failed to grade essay");
      // }

      // const result = await response.json();
      // setScore(result.score);
      setScore(1);
      setOpenDialog(true);
    } catch (error) {
      console.error("Error grading essay:", error);
      setError("An error occurred while grading your essay. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    onComplete(score, true, timer);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Container maxWidth="md">
      <Fade in={true} timeout={1000}>
        <StyledPaper elevation={3}>
          <AnimatedTypography
            variant="h4"
            gutterBottom
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            English Writing Test
          </AnimatedTypography>

          <AnimatedTypography
            variant="h6"
            gutterBottom
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            Topic: {topic}
          </AnimatedTypography>

          {!isTestStarted ? (
            <Box sx={{ textAlign: "center", my: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartTest}
                size="large"
              >
                Start Writing Test
              </Button>
            </Box>
          ) : (
            <>
              <Box sx={{ my: 4 }}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  variant="outlined"
                  value={essay}
                  onChange={handleEssayChange}
                  placeholder="Write your essay here..."
                  disabled={isSubmitting} // Disable editing after submission
                />
                <Typography variant="body2" align="right" sx={{ mt: 1 }}>
                  Character count: {characterCount} / {MINIMUM_CHARACTERS}{" "}
                  minimum
                </Typography>
              </Box>

              <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                Time elapsed: {formatTime(timer)}
              </Typography>

              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {error}
                </Alert>
              )}

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting || characterCount < MINIMUM_CHARACTERS}
                  size="large"
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Submit Essay"
                  )}
                </Button>
              </Box>
            </>
          )}
        </StyledPaper>
      </Fade>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Your IELTS Writing Score</DialogTitle>
        <DialogContent>
          <Typography
            variant="h2"
            align="center"
            color="primary"
            sx={{ my: 4 }}
          >
            {score}
          </Typography>
          <Typography variant="body1">
            This score is an estimate based on your written essay. In an actual
            IELTS test, your writing would be evaluated on four criteria: Task
            Achievement, Coherence and Cohesion, Lexical Resource, and
            Grammatical Range and Accuracy.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default WritingEnglishTest;
