import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/AxiosConfig";
import ErrorPage from "../../Utils/ErrorPage";
import MarkdownRenderer from "../../Utils/MarkdownRenderer";
import { CircularProgress } from "@mui/material";

const BlogPostDetail = () => {
  const location = useLocation();
  const { blog } = location.state || {};

  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const encodedContentUrl = encodeURIComponent(blog.contentUrl);
        const response = await axiosInstance.get(`blog/content`, {
          params: { contentUrl: encodedContentUrl },
        });
        setContent(response.data.content);
      } catch (error) {
        console.error("Error fetching markdown content:", error);
      }
      setLoading(false);
    };

    if (blog && blog.contentUrl) {
      fetchContent();
    } else {
      setLoading(false);
    }
  }, [blog]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  if (!blog) {
    return <ErrorPage />;
  }

  const createdAtDate = new Date(blog.createdAt);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 pt-20 pb-10 px-5">
      <div className="bg-white shadow-lg rounded-lg p-5 w-full md:w-3/4 lg:w-1/2">
        <h1 className="text-3xl font-bold mb-5 text-center">{blog.title}</h1>
        {blog.image && (
          <img
            src={blog.image}
            alt={blog.title}
            className="w-full h-auto mb-5 rounded-md"
          />
        )}
        <div className="text-left mb-5">
          <p className="text-gray-500 text-sm">
            {`Written by ${
              blog.createdBy
            } on ${createdAtDate.toLocaleDateString()}`}
          </p>
          <p className="text-gray-500 text-sm">{`Category: ${blog.category}`}</p>
        </div>
        <div className="mb-5">
          <MarkdownRenderer content={content} />
        </div>
      </div>
    </div>
  );
};

export default BlogPostDetail;
