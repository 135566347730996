import { useEffect } from "react";

const InitializeGapi = () => {
  useEffect(() => {
    function initClient() {
      gapi.client
        .init({
          apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
          clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
          ],
          scope: "https://www.googleapis.com/auth/drive.metadata.readonly",
        })
        .then(
          function () {
            console.log("GAPI client initialized.");
          },
          function (error) {
            console.error("Error initializing GAPI client:", error);
          }
        );
    }

    function handleClientLoad() {
      gapi.load("client:auth2", initClient);
    }

    window.onload = handleClientLoad;
  }, []);

  return null;
};

export default InitializeGapi;
