import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Fade,
  Zoom,
  ThemeProvider,
  createTheme,
  CssBaseline,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import AceEditor from 'react-ace';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-kotlin';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-swift';
import 'ace-builds/src-noconflict/theme-tomorrow';

const theme = createTheme();

const Container = styled(Box)({
  display: 'flex',
  height: '100vh',
  backgroundColor: '#f5f5f5',
  color: '#333',
});

const Panel = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  overflow: 'hidden',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const DesignReference = styled('img')({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
  marginTop: '20px',
  borderRadius: '4px',
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: theme.spacing(2),
}));

const OutputPanel = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  maxHeight: '200px',
  overflowY: 'auto',
}));

const SubmittedMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.contrastText,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
}));

const domains = {
  frontend: {
    title: "Responsive Navigation Bar",
    description:
      "Create a responsive navigation bar that collapses into a hamburger menu on mobile devices. Ensure it's accessible and follows modern web standards.",
    designUrl:
      "https://media.dev.to/cdn-cgi/image/width=1000,height=420,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2F7b5gr5llyya6bz3a6u5d.jpg",
    starterCode: {
      html: "<nav>\n  <!-- Add your navigation HTML here -->\n</nav>",
      css: "/* Add your CSS styles here */",
      javascript: "// Add your JavaScript code here if needed",
    },
  },
  backend: {
    title: "RESTful API for User Management",
    description:
      "Implement a RESTful API for user management with endpoints for registration, authentication, and profile updates. Use best practices for security and data validation.",
    starterCode: {
      javascript:
        "const express = require('express');\nconst app = express();\n\n// Implement your API endpoints here",
      python:
        "from flask import Flask\napp = Flask(__name__)\n\n# Implement your API endpoints here",
    },
  },
  android: {
    title: "Social Media Feed",
    description:
      "Create a scrollable social media feed in Android displaying posts with images, text, and user interactions (like, comment, share). Implement efficient list rendering and image loading.",
    starterCode: {
      java: "public class FeedActivity extends AppCompatActivity {\n    // Implement your Android feed here\n}",
      kotlin: "class FeedActivity : AppCompatActivity() {\n    // Implement your Android feed here\n}",
    },
  },
  ios: {
    title: "Fitness Tracking App",
    description:
      "Develop a fitness tracking app in iOS that records user activities, displays statistics, and provides workout recommendations. Integrate with HealthKit for data synchronization.",
    apiEndpoint:
      "GET https://api.example.com/workouts, POST https://api.example.com/activities",
    designUrl:
      "https://placeholder.com/wp-content/uploads/2018/10/placeholder-1024x800.png",
    starterCode: {
      swift:
        "import UIKit\n\nclass FitnessViewController: UIViewController {\n    // Implement your iOS fitness tracking functionality here\n}",
    },
  },
  dataScience: {
    title: "Customer Churn Prediction",
    description:
      "Build a machine learning model to predict customer churn for a subscription-based service. Use historical customer data to train your model and evaluate its performance.",
    apiEndpoint: "GET https://api.example.com/customer_data",
    designUrl:
      "https://placeholder.com/wp-content/uploads/2018/10/placeholder-1024x800.png",
    starterCode: {
      python:
        "import pandas as pd\nimport sklearn\n\n# Implement your machine learning model here",
    },
  },
  devops: {
    title: "Automated Deployment Pipeline",
    description:
      "Create a CI/CD pipeline using Jenkins or GitLab CI to automatically build, test, and deploy a web application to a Kubernetes cluster. Implement proper error handling and notifications.",
    designUrl:
      "https://placeholder.com/wp-content/uploads/2018/10/placeholder-1024x800.png",
    starterCode: {
      yaml: "# Add your CI/CD configuration here\n",
      bash: "#!/bin/bash\n# Add your deployment scripts here",
    },
  },
};

const DomainSpecificTest = ({ onSubmit }) => {
  const [domain, setDomain] = useState('frontend');
  const [activeTab, setActiveTab] = useState('html');
  const [code, setCode] = useState(domains.frontend.starterCode);
  const [output, setOutput] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = null;
    if (!isSubmitted) {
      interval = setInterval(() => {
        setTimer((seconds) => seconds + 1);
      }, 1000);
    } else if (isSubmitted && timer !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isSubmitted, timer]);

  const handleDomainChange = (event) => {
    const newDomain = event.target.value;
    setDomain(newDomain);
    setCode(domains[newDomain].starterCode);
    setActiveTab(Object.keys(domains[newDomain].starterCode)[0]);
    setIsSubmitted(false);
    setOutput('');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCodeChange = (newValue) => {
    setCode({ ...code, [activeTab]: newValue });
  };

  const runCode = () => {
    setOutput(
      'Code execution is simulated. In a real environment, this would run your code and display the output or errors.'
    );
  };

  const submitCode = () => {
    setIsSubmitted(true);
    const score = evaluateCode(domain, code);
    onSubmit({ domain, code, score, time: timer });
  };

  const evaluateCode = (domain, code) => {
    // Implement code evaluation logic here
    // This is a placeholder function
    return Math.floor(Math.random() * 100);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <Panel>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h4">Domain Specific Test</Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="h6" mr={2}>
                Time: {formatTime(timer)}
              </Typography>
              <FormControl variant="outlined" style={{ minWidth: 200 }}>
                <InputLabel>Domain</InputLabel>
                <Select
                  value={domain}
                  onChange={handleDomainChange}
                  label="Domain"
                >
                  {Object.keys(domains).map((key) => (
                    <MenuItem key={key} value={key}>
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Fade in={true} timeout={500}>
            <StyledCard>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {domains[domain].title}
                </Typography>
                <Typography variant="body1">
                  {domains[domain].description}
                </Typography>
              </CardContent>
            </StyledCard>
          </Fade>
          {domain === 'frontend' && (
            <Zoom in={true} timeout={500}>
              <DesignReference
                src={domains[domain].designUrl}
                alt="Design Reference"
              />
            </Zoom>
          )}
        </Panel>
        <Panel>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            {Object.keys(domains[domain].starterCode).map((lang) => (
              <Tab key={lang} label={lang.toUpperCase()} value={lang} />
            ))}
          </Tabs>
          <Box flexGrow={1} mt={2}>
            <AceEditor
              mode={activeTab}
              theme="tomorrow"
              onChange={handleCodeChange}
              value={code[activeTab]}
              name="code-editor"
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
              width="100%"
              height="calc(100% - 100px)"
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <StyledButton
              variant="contained"
              color="primary"
              onClick={runCode}
              startIcon={<PlayArrowIcon />}
              disabled={isSubmitted}
            >
              Run Code
            </StyledButton>
            <StyledButton
              variant="contained"
              color="secondary"
              onClick={submitCode}
              disabled={isSubmitted}
            >
              {isSubmitted ? 'Submitted' : 'Finish'}
            </StyledButton>
          </Box>
          {isSubmitted ? (
            <SubmittedMessage>
              <CheckCircleIcon sx={{ mr: 1 }} />
              We will get back to you soon
            </SubmittedMessage>
          ) : (
            <Fade in={output !== ''} timeout={500}>
              <OutputPanel>
                <Typography variant="h6" gutterBottom>
                  Output:
                </Typography>
                <pre>{output}</pre>
              </OutputPanel>
            </Fade>
          )}
        </Panel>
      </Container>
    </ThemeProvider>
  );
};

export default DomainSpecificTest;
