import React from "react";

const SectionTitle = ({ title }) => {
  return (
    <div className="flex flex-col items-center space-y-2 text-center">
      <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-csb-500">
        {title}
      </h1>
      <div className="w-12 sm:w-16 h-1 bg-csb-500 rounded-full"></div>
    </div>
  );
};

export default SectionTitle;
