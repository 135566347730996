import React from "react";
import HeroSection from "./components/HeroSection";
import WhyCSBSection from "./components/WhyCSBSection";
import ReasonSection from "./components/ReasonSection";
import HackCSBSection from "./components/HackCSBSection";
import TestimonialsSection from "./components/TestimonialSection";
import AsSeenOnSection from "./components/AsSeenOnSection";
import StatsSection from "./components/StatsSection";

const LandingMainContent = () => {
  return (
    <main>
      <HeroSection />
      <WhyCSBSection />
      {/* <ReasonSection /> */}
      <StatsSection />
      <AsSeenOnSection />
      <TestimonialsSection />
    </main>
  );
};

export default LandingMainContent;
