import React from "react";
import HeroSection from "../LandingPage/components/HeroSection";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const teamMembers = [
  {
    name: "Mostofa Adib Shakib",
    role: "Founder",
    imageUrl: "/judges/adib.png",
    linkedIn: "https://www.linkedin.com/in/adibshakib/",
  },
  {
    name: "Jarin Rafa",
    role: "Head of Operations",
    imageUrl: "./judges/rafa.png",
    linkedIn: "https://www.linkedin.com/in/jarinrafa/",
  },
  {
    name: "Nazifa Mouli",
    role: "Head of R&D",
    imageUrl: "./judges/nazifa.png",
    linkedIn: "https://www.linkedin.com/in/nazifamouli/",
  },
  {
    name: "Abtahi Noor",
    role: "Software Developer",
    imageUrl: "../team/abtahi.jpeg",
    linkedIn: "https://www.linkedin.com/in/smabtahinoor/",
  },
  {
    name: "Shahriar Ragib",
    role: "Graphic Designer",
    imageUrl: "../team/shahriar.jpeg",
    linkedIn: "https://www.linkedin.com/in/shariarragib/",
  },
  {
    name: "Omar Faruk Tanzill",
    role: "Content Strategy",
    imageUrl: "../team/omar.jpeg",
    linkedIn: "https://www.linkedin.com/in/omar-faruk-tanzil/",
  },
  {
    name: "Sazzad Hossain",
    role: "Community Manager",
    imageUrl: "../team/sazzad.jpeg",
    linkedIn: "https://www.linkedin.com/in/sazzad12/",
  },
  {
    name: "Mehedi Hassan Polash",
    role: "Community Manager",
    imageUrl: "../team/polash.jpeg",
    linkedIn: "https://www.linkedin.com/in/mehedi-hasan-5447382b6/",
  },
  {
    name: "Taofique Islam",
    role: "Community Outreach",
    imageUrl: "../team/taofique.jpeg",
    linkedIn: "https://www.linkedin.com/in/taofique/",
  },
];

const StyledTypography = styled(Typography)({
  fontFamily: "'Poppins', sans-serif",
});

const TeamBody = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-50 to-indigo-50">
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-5xl font-bold text-center mb-10 text-indigo-700 pt-16"
      >
        Meet Our Team
      </motion.h2>
      <div className="p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {teamMembers.map((member, index) => (
          <motion.div
            key={member.name}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="max-w-sm w-full rounded-lg overflow-hidden bg-white shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <div className="bg-gradient-to-r from-indigo-500 to-purple-600 px-6 py-4 text-center">
              <StyledTypography
                variant="h5"
                className="font-bold text-white mb-2"
              >
                {member.name}
              </StyledTypography>
              <StyledTypography variant="subtitle1" className="text-indigo-100">
                {member.role}
              </StyledTypography>
            </div>
            <motion.img
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="w-48 h-48 mx-auto rounded-full mt-6 border-4 border-indigo-300"
              src={member.imageUrl}
              alt={member.name}
            />
            <div className="px-6 py-4 text-center">
              <a
                href={member.linkedIn}
                className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                LinkedIn
              </a>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const Team = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 64px)",
      }}
    >
      <HeroSection />
      <TeamBody />
    </div>
  );
};

export default Team;
