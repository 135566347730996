import React from "react";
import SectionTitle from "../LandingPage/components/SectionTitle";
import { FaLinkedin } from "react-icons/fa";

const SpeakerSection = () => {
  const speakers = [
    {
      name: "Tamzid Islam",
      qualification:
        "Senior Engineer at Apple, ex-Engineer at Qualcomm & Intel, EECS at UC Berkeley",
      imageUrl: "/speakers/tamzid.png",
      linkedInUrl: "https://www.linkedin.com/in/tamzid/",
    },
    {
      name: "Seeam Shahid Noor",
      qualification:
        "Sr. Technical Product Manager, YouTuber with 900K Subscribers, IBM & Harvard University",
      imageUrl: "/speakers/seeam.png",
      linkedInUrl: "https://www.linkedin.com/in/seeamshahidnoor/",
    },
    {
      name: "Nuzhat Minhaz",
      qualification:
        "Product Manager at Microsoft, 5x Hackathon Winner, Founder of PrivaC (Cyber-Safety Startup)",
      imageUrl: "/speakers/nuzhat.png",
      linkedInUrl: "https://www.linkedin.com/in/nuzhatminhaz/",
    },
  ];

  const SpeakerCard = ({ speaker }) => {
    return (
      <>
        <div
          className="flex flex-col space-y-1 justify-center items-center"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <img
            src={speaker.imageUrl}
            alt={speaker.name}
            className="w-48 h-48 rounded-full object-cover border-2 border-csb-500"
          />

          <h1 className="font-bold pt-2">{speaker.name}</h1>
          <div className="text-gray-700 text-center text-sm">
            {speaker.qualification.split(",").map((part, index) => (
              <p key={index}>{part}</p>
            ))}
          </div>
          <a
            className="hover:text-csb-500 text-xl"
            href={speaker.linkedInUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      </>
    );
  };

  return (
    <section className="max-w-screen-xl mx-auto py-12">
      <div data-aos="fade-up" data-aos-duration="1000">
        <SectionTitle title="Keynote Speakers" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 px-8 lg:px-0 py-8">
        {speakers.map((speaker, index) => (
          <SpeakerCard key={index} speaker={speaker} />
        ))}
      </div>
    </section>
  );
};

export default SpeakerSection;
