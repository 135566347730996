import React from "react";
import { Typography, Container, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const ComingSoonPage = () => {
  return (
    <Container className="flex flex-col items-center justify-center min-h-screen bg-transparent">
      <Box className="flex flex-col items-center justify-center text-center p-8 bg-white rounded-lg shadow-lg">
        <FontAwesomeIcon
          icon={faClock}
          className="text-6xl text-red-500 mb-4"
        />
        <Typography variant="h4" component="h1" className="mb-4 text-gray-700">
          Coming Soon!
        </Typography>
        <Typography variant="body1" className="mb-6 text-gray-600">
          We're working hard to bring you this feature. Stay tuned for updates.
        </Typography>
      </Box>
    </Container>
  );
};

export default ComingSoonPage;
