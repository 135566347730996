import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Alert,
  Grid,
  Modal,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useDropzone } from "react-dropzone";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SpokenEnglishTest from "../Assessments/SpokenEnglishTest";
import WritingEnglishTest from "../Assessments/WritingEnglishTest";
import ListeningTest from "../Assessments/ListeningEnglishTest";
import PersonalityTest from "../Assessments/PersonalityTest";
import ProblemSolvingTest from "../Assessments/ProblemSolvingTest";
import DomainSpecificTest from "../Assessments/DomainSpecificTest";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: "16px",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 15px 40px rgba(0, 0, 0, 0.15)",
  },
  marginTop: theme.spacing(8),
}));

const Headline = styled(motion.h1)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight: 700,
  marginBottom: theme.spacing(3),
  color: theme.palette.primary?.main || "#1976d2",
  textAlign: "center",
}));

const Blurb = styled(motion.p)(({ theme }) => ({
  fontSize: "1.2rem",
  marginBottom: theme.spacing(6),
  color: theme.palette.text?.secondary || "#666",
  textAlign: "center",
  maxWidth: "800px",
  margin: "0 auto",
}));

const steps = [
  "Personal Details",
  "Resume Upload",
  "Qualifications",
  "English Speaking Test",
  "English Listening Test",
  "English Writing Test",
  "Personality Test",
  "Problem Solving Test",
  "Domain-Specific Test",
];

// Define the domains object
const domains = {
  frontend: {
    title: "Responsive Navigation Bar",
    description:
      "Create a responsive navigation bar that collapses into a hamburger menu on mobile devices. Ensure it's accessible and follows modern web standards.",
    starterCode: {
      html: "<nav>\n  <!-- Add your navigation HTML here -->\n</nav>",
      css: "/* Add your CSS styles here */",
      javascript: "// Add your JavaScript code here if needed",
    },
  },
  backend: {
    title: "RESTful API for User Management",
    description:
      "Implement a RESTful API for user management with endpoints for registration, authentication, and profile updates. Use best practices for security and data validation.",
    starterCode: {
      javascript:
        "const express = require('express');\nconst app = express();\n\n// Implement your API endpoints here",
      python:
        "from flask import Flask\napp = Flask(__name__)\n\n# Implement your API endpoints here",
    },
  },
  android: {
    title: "Social Media Feed",
    description:
      "Create a scrollable social media feed in Android displaying posts with images, text, and user interactions (like, comment, share). Implement efficient list rendering and image loading.",
    starterCode: {
      java: "public class FeedActivity extends AppCompatActivity {\n    // Implement your Android feed here\n}",
      kotlin:
        "class FeedActivity : AppCompatActivity() {\n    // Implement your Android feed here\n}",
    },
  },
  ios: {
    title: "Weather App",
    description:
      "Develop a weather app that displays current weather conditions and a 5-day forecast. Implement location services and integrate with a weather API.",
    starterCode: {
      swift:
        "import UIKit\n\nclass WeatherViewController: UIViewController {\n    // Implement your weather app here\n}",
    },
  },
};

const SuccessModal = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        textAlign: "center",
      }}
    >
      <CheckCircleIcon sx={{ fontSize: 60, color: "success.main", mb: 2 }} />
      <Typography variant="h5" component="h2">
        Thank you for applying!
      </Typography>
      <Typography sx={{ mt: 2 }}>We will get back to you shortly!</Typography>
    </Box>
  </Modal>
);

const Employee = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    salaryExpectation: "",
    availability: "",
    resume: null,
    education: [],
    workHistory: [],
    skills: [],
    englishProficiency: null,
    technicalProficiency: null,
    domainSpecificTest: null,
  });
  const [loading, setLoading] = useState(false);
  const [educationEntries, setEducationEntries] = useState([
    { institution: "", degree: "", graduationYear: "", gpa: "" },
  ]);
  const [workEntries, setWorkEntries] = useState([
    { company: "", position: "", startDate: "", endDate: "", description: "" },
  ]);
  const [skillEntries, setSkillEntries] = useState([""]);
  const [error, setError] = useState("");
  const [englishSpeakingScore, setEnglishSpeakingScore] = useState(null);
  const [englishWritingScore, setEnglishWritingScore] = useState(null);
  const [speakingTestCompleted, setSpeakingTestCompleted] = useState(false);
  const [writingTestCompleted, setWritingTestCompleted] = useState(false);
  const [writingTestTime, setWritingTestTime] = useState(null);
  const [listeningTestCompleted, setListeningTestCompleted] = useState(false);
  const [listeningTestScore, setListeningTestScore] = useState(null);
  const [personalityTestCompleted, setPersonalityTestCompleted] =
    useState(false);
  const [personalityType, setPersonalityType] = useState(null);
  const [problemSolvingTestCompleted, setProblemSolvingTestCompleted] =
    useState(false);
  const [problemSolvingScore, setProblemSolvingScore] = useState(null);
  const [domainSpecificTestCompleted, setDomainSpecificTestCompleted] =
    useState(false);
  const [domainSpecificScore, setDomainSpecificScore] = useState(null);
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // Load saved progress from localStorage
    const savedProgress = localStorage.getItem("employeeOnboardingProgress");
    if (savedProgress) {
      const { step, data } = JSON.parse(savedProgress);
      setActiveStep(step);
      setFormData(data);
    }
  }, []);

  const saveProgress = (step, data) => {
    localStorage.setItem(
      "employeeOnboardingProgress",
      JSON.stringify({ step, data })
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => {
      const newData = { ...prev, [name]: value };
      saveProgress(activeStep, newData);
      return newData;
    });
  };

  const validatePersonalDetails = () => {
    return (
      formData.name &&
      formData.email &&
      formData.phone &&
      formData.salaryExpectation &&
      formData.availability
    );
  };

  const validateResumeUpload = () => {
    return formData.resume !== null;
  };

  const validateQualifications = () => {
    // Check if at least one education entry is filled
    const isEducationValid = educationEntries.some(
      (edu) => edu.institution && edu.degree && edu.graduationYear && edu.gpa
    );

    // Check if at least one work entry is filled
    const isWorkValid = workEntries.some(
      (work) =>
        work.company && work.position && work.startDate && work.description
    );

    // Check if at least one skill is entered
    const isSkillValid = skillEntries.some((skill) => skill.trim() !== "");

    if (!isEducationValid) {
      setError("Please fill in at least one complete education entry.");
      return false;
    }

    if (!isWorkValid) {
      setError("Please fill in at least one complete work history entry.");
      return false;
    }

    if (!isSkillValid) {
      setError("Please enter at least one skill.");
      return false;
    }

    return true;
  };

  const handleNext = () => {
    if (activeStep === 3 && !speakingTestCompleted) {
      alert("Please complete the speaking test before proceeding.");
      return;
    }
    if (activeStep === 4 && !listeningTestCompleted) {
      alert("Please complete the listening test before proceeding.");
      return;
    }
    if (activeStep === 5 && !writingTestCompleted) {
      alert("Please complete the writing test before proceeding.");
      return;
    }
    let isValid = false;
    switch (activeStep) {
      case 0:
        isValid = validatePersonalDetails();
        break;
      case 1:
        isValid = validateResumeUpload();
        break;
      case 2:
        isValid = validateQualifications();
        break;
      default:
        isValid = true;
    }

    if (!isValid) {
      return;
    }

    if (activeStep === 2) {
      // Update formData with the latest entries
      setFormData((prev) => ({
        ...prev,
        education: educationEntries,
        workHistory: workEntries,
        skills: skillEntries.filter((skill) => skill.trim() !== ""),
      }));
    }

    setActiveStep((prev) => {
      const newStep = prev + 1;
      saveProgress(newStep, formData);
      return newStep;
    });
    setError("");
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
    setError("");
  };

  const handleDomainTestSubmit = async (testData) => {
    setFormData((prevData) => ({
      ...prevData,
      domainSpecificTest: testData,
    }));

    try {
      // Send all form data to the database
      await handleSubmit();

      // Show success modal
      setModalOpen(true);

      // Reset form after a delay
      setTimeout(() => {
        setActiveStep(0);
        setFormData({
          name: "",
          email: "",
          phone: "",
          salaryExpectation: "",
          availability: "",
          resume: null,
          education: [],
          workHistory: [],
          skills: [],
          englishProficiency: null,
          technicalProficiency: null,
          domainSpecificTest: null,
        });
        setModalOpen(false);
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // TODO: Implement API call to submit the form data
      console.log("Submitting form data:", formData);
      // Simulating API call
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
      setSubmissionComplete(true);
      // Clear saved progress after successful submission
      localStorage.removeItem("employeeOnboardingProgress");
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      // Handle error (e.g., show error message to user)
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    onDrop: (acceptedFiles) => {
      setFormData((prev) => {
        const newData = { ...prev, resume: acceptedFiles[0] };
        saveProgress(activeStep, newData);
        return newData;
      });
    },
  });

  const handleEducationChange = (index, field, value) => {
    const newEntries = [...educationEntries];
    newEntries[index][field] = value;
    setEducationEntries(newEntries);
  };

  const handleWorkChange = (index, field, value) => {
    const newEntries = [...workEntries];
    newEntries[index][field] = value;
    setWorkEntries(newEntries);
  };

  const handleSkillChange = (index, value) => {
    const newEntries = [...skillEntries];
    newEntries[index] = value;
    setSkillEntries(newEntries);
  };

  const addEducationEntry = () => {
    setEducationEntries([
      ...educationEntries,
      { institution: "", degree: "", graduationYear: "", gpa: "" },
    ]);
  };

  const addWorkEntry = () => {
    setWorkEntries([
      ...workEntries,
      {
        company: "",
        position: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ]);
  };

  const addSkillEntry = () => {
    setSkillEntries([...skillEntries, ""]);
  };

  const removeEducationEntry = (index) => {
    const newEntries = educationEntries.filter((_, i) => i !== index);
    setEducationEntries(newEntries);
  };

  const removeWorkEntry = (index) => {
    const newEntries = workEntries.filter((_, i) => i !== index);
    setWorkEntries(newEntries);
  };

  const removeSkillEntry = (index) => {
    const newEntries = skillEntries.filter((_, i) => i !== index);
    setSkillEntries(newEntries);
  };

  const handleEnglishSpeakingTestComplete = (score, completed) => {
    setEnglishSpeakingScore(score);
    setSpeakingTestCompleted(completed);
    if (completed) {
      handleNext();
    }
  };

  const handleEnglishWritingTestComplete = (score, completed, time) => {
    setEnglishWritingScore(score);
    setWritingTestCompleted(completed);
    setWritingTestTime(time);
    if (completed) {
      handleNext();
    }
  };

  const handleListeningTestComplete = (score) => {
    setListeningTestScore(score);
    setListeningTestCompleted(true);
    handleNext();
  };

  const handlePersonalityTestComplete = (type) => {
    setPersonalityType(type);
    setPersonalityTestCompleted(true);
    handleNext();
  };

  const handleProblemSolvingTestComplete = (score) => {
    setProblemSolvingScore(score);
    setProblemSolvingTestCompleted(true);
    handleNext();
  };

  const handleDomainSpecificTestComplete = (score) => {
    setDomainSpecificScore(score);
    setDomainSpecificTestCompleted(true);
    handleNext();
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <TextField
              fullWidth
              margin="normal"
              name="name"
              label="Full Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              name="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              name="phone"
              label="Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              name="salaryExpectation"
              label="Salary Expectation (USD/year)"
              type="number"
              value={formData.salaryExpectation}
              onChange={handleChange}
              required
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Availability</InputLabel>
              <Select
                name="availability"
                value={formData.availability}
                onChange={handleChange}
              >
                <MenuItem value="Immediate">Immediate</MenuItem>
                <MenuItem value="2 weeks">2 weeks</MenuItem>
                <MenuItem value="1 month">1 month</MenuItem>
                <MenuItem value="2 months">2 months</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      case 1:
        return (
          <>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #ccc",
                p: 3,
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              {formData.resume ? (
                <Typography>File uploaded: {formData.resume.name}</Typography>
              ) : (
                <Typography>
                  Drag and drop your resume here, or click to select a file
                </Typography>
              )}
            </Box>
          </>
        );
      case 2:
        return (
          <>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <Typography variant="h6" gutterBottom>
              Education
            </Typography>
            {educationEntries.map((edu, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Institution"
                      value={edu.institution}
                      onChange={(e) =>
                        handleEducationChange(
                          index,
                          "institution",
                          e.target.value
                        )
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Degree"
                      value={edu.degree}
                      onChange={(e) =>
                        handleEducationChange(index, "degree", e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Graduation Year"
                      value={edu.graduationYear}
                      onChange={(e) =>
                        handleEducationChange(
                          index,
                          "graduationYear",
                          e.target.value
                        )
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="GPA"
                      value={edu.gpa}
                      onChange={(e) =>
                        handleEducationChange(index, "gpa", e.target.value)
                      }
                      required
                    />
                  </Grid>
                </Grid>
                {index > 0 && (
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => removeEducationEntry(index)}
                    sx={{ mt: 1 }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={addEducationEntry}
              sx={{ mt: 2, mb: 4 }}
            >
              Add Education
            </Button>

            <Typography variant="h6" gutterBottom>
              Work History
            </Typography>
            {workEntries.map((work, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Company"
                      value={work.company}
                      onChange={(e) =>
                        handleWorkChange(index, "company", e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Position"
                      value={work.position}
                      onChange={(e) =>
                        handleWorkChange(index, "position", e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Start Date"
                      value={work.startDate}
                      onChange={(e) =>
                        handleWorkChange(index, "startDate", e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="End Date"
                      value={work.endDate}
                      onChange={(e) =>
                        handleWorkChange(index, "endDate", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      label="Job Description"
                      value={work.description}
                      onChange={(e) =>
                        handleWorkChange(index, "description", e.target.value)
                      }
                      required
                    />
                  </Grid>
                </Grid>
                {index > 0 && (
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => removeWorkEntry(index)}
                    sx={{ mt: 1 }}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={addWorkEntry}
              sx={{ mt: 2, mb: 4 }}
            >
              Add Work Experience
            </Button>

            <Typography variant="h6" gutterBottom>
              Skills
            </Typography>
            {skillEntries.map((skill, index) => (
              <Box
                key={index}
                sx={{ mb: 2, display: "flex", alignItems: "center" }}
              >
                <TextField
                  fullWidth
                  label="Skill"
                  value={skill}
                  onChange={(e) => handleSkillChange(index, e.target.value)}
                  required
                />
                {index > 0 && (
                  <IconButton
                    onClick={() => removeSkillEntry(index)}
                    sx={{ ml: 1 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={addSkillEntry}
              sx={{ mt: 2 }}
            >
              Add Skill
            </Button>
          </>
        );
      case 3:
        return speakingTestCompleted ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CheckCircleIcon sx={{ color: "success.main", fontSize: 40 }} />
          </Box>
        ) : (
          <SpokenEnglishTest onComplete={handleEnglishSpeakingTestComplete} />
        );
      case 4:
        return listeningTestCompleted ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CheckCircleIcon sx={{ color: "success.main", fontSize: 40 }} />
          </Box>
        ) : (
          <ListeningTest onComplete={handleListeningTestComplete} />
        );
      case 5:
        return writingTestCompleted ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CheckCircleIcon sx={{ color: "success.main", fontSize: 40 }} />
          </Box>
        ) : (
          <WritingEnglishTest onComplete={handleEnglishWritingTestComplete} />
        );
      case 6:
        return personalityTestCompleted ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CheckCircleIcon sx={{ color: "success.main", fontSize: 40 }} />
          </Box>
        ) : (
          <PersonalityTest onComplete={handlePersonalityTestComplete} />
        );
      case 7:
        return problemSolvingTestCompleted ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CheckCircleIcon sx={{ color: "success.main", fontSize: 40 }} />
          </Box>
        ) : (
          <ProblemSolvingTest onComplete={handleProblemSolvingTestComplete} />
        );
      case 8:
        return (
          <DomainSpecificTest
            onSubmit={handleDomainTestSubmit}
            domains={domains}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 12, mb: 12 }}>
      <Headline
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Join the Top 1% of Developers
      </Headline>
      <Blurb
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Showcase your skills and connect with top SMEs in Western countries.
        Complete your profile and assessments to stand out in the global talent
        pool.
      </Blurb>

      <StyledPaper
        elevation={0}
        component={motion.div}
        whileHover={{ scale: 1.02 }}
      >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Button
            variant="contained"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={
                loading ||
                (activeStep === 3 && !speakingTestCompleted) ||
                (activeStep === 4 && !listeningTestCompleted) ||
                (activeStep === 5 && !writingTestCompleted) ||
                (activeStep === 6 && !personalityTestCompleted) ||
                (activeStep === 7 && !problemSolvingTestCompleted) ||
                (activeStep === 8 && !domainSpecificTestCompleted)
              }
            >
              Next
            </Button>
          )}
        </Box>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        <SuccessModal open={modalOpen} onClose={() => setModalOpen(false)} />
      </StyledPaper>
    </Container>
  );
};

export default Employee;
