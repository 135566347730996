import React from "react";
import { Modal, Box, Typography, Button, useMediaQuery } from "@mui/material";
import {
  signInWithGoogle,
  signInWithFacebook,
  signInWithGitHub,
} from "../Authentication/AuthService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
};

const LoginModal = ({ open, handleClose }) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const buttonStyle = {
    width: "80%",
    height: "15%",
    margin: "10px 0",
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await signInWithGoogle();
      console.log("Google login success:", response);
      handleClose();
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };

  // const handleFacebookLogin = async () => {
  //   try {
  //     const response = await signInWithFacebook();
  //     console.log("Facebook login success:", response);
  //     handleClose();
  //   } catch (error) {
  //     console.error("Facebook login failed:", error);
  //   }
  // };

  const handleGitHubLogin = async () => {
    try {
      const response = await signInWithGitHub();
      console.log("GitHub login success:", response);
      handleClose();
    } catch (error) {
      console.error("GitHub login failed:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
      slotProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
    >
      <Box
        className={`max-w-md mx-auto ${
          isSmallScreen ? "w-full p-2" : "w-3/5 p-4"
        }`}
        sx={style}
      >
        <Typography id="login-modal-title" variant="h4" component="h2">
          Login
        </Typography>
        <Typography id="login-modal-description" sx={{ mt: 2 }}>
          Choose your preferred login method:
        </Typography>
        <Button
          sx={buttonStyle}
          className="text-base md:text-lg whitespace-nowrap"
          onClick={handleGoogleLogin}
          variant="contained"
        >
          Login with Google
        </Button>
        {/* <Button
          sx={buttonStyle}
          onClick={handleFacebookLogin}
          variant="contained"
          color="primary"
        >
          Login with Facebook
        </Button> */}
        <Button
          sx={buttonStyle}
          className="text-base md:text-lg whitespace-nowrap"
          onClick={handleGitHubLogin}
          variant="contained"
          color="primary"
        >
          Login with GitHub
        </Button>
      </Box>
    </Modal>
  );
};

export default LoginModal;
