import React from "react";
import {
  RoadmapContainer,
  Topic,
  VerticalArrow,
  HorizontalArrow,
  RowContainer,
  RoadmapTitle
} from "./RoadmapComponents";

const FrontendRoadmap = () => {
  return (
    <RoadmapContainer>
      <RoadmapTitle title="Front-end Roadmap" />

      <Topic
        title="Internet Basics"
        subtopics={["How the web works", "HTTP/HTTPS", "DNS"]}
      />

      <VerticalArrow />

      <RowContainer>
        <Topic
          title="HTML"
          subtopics={["Semantic HTML", "Forms and Validations", "Accessibility"]}
        />
        <HorizontalArrow />
        <Topic
          title="CSS"
          subtopics={["Layouts", "Responsive Design", "CSS Preprocessors"]}
        />
      </RowContainer>

      <VerticalArrow />

      <Topic
        title="JavaScript"
        subtopics={["Syntax", "DOM Manipulation", "ES6+", "Async Programming"]}
      />

      <VerticalArrow />

      <RowContainer>
        <Topic
          title="Version Control"
          subtopics={["Git", "GitHub"]}
        />
        <HorizontalArrow />
        <Topic
          title="Package Managers"
          subtopics={["npm", "yarn"]}
        />
      </RowContainer>

      <VerticalArrow />

      <Topic
        title="Frontend Frameworks"
        subtopics={["React", "Vue", "Angular"]}
      />

      <VerticalArrow />

      <RowContainer>
        <Topic
          title="State Management"
          subtopics={["Redux", "MobX", "Context API"]}
        />
        <HorizontalArrow />
        <Topic
          title="Testing"
          subtopics={["Jest", "React Testing Library", "Cypress"]}
        />
      </RowContainer>

      <VerticalArrow />

      <Topic
        title="Build Tools"
        subtopics={["Webpack", "Babel", "ESLint"]}
      />
    </RoadmapContainer>
  );
};

export default FrontendRoadmap;
