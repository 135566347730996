import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Grid, Container, useMediaQuery } from "@mui/material";

function AnimatedCounterSection() {
  const participantsCount = 600;
  const teamsCount = 300;
  const organizationsCount = 70;

  const participantsRef = useRef(null);
  const teamsRef = useRef(null);
  const orgnizationRef = useRef(null);

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const animateValue = (ref, targetValue) => {
      let startTimestamp = null;
      const duration = 2000;

      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;

        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const currentValue = Math.floor(progress * targetValue);
        ref.current.textContent = currentValue;

        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    };

    animateValue(participantsRef, participantsCount);
    animateValue(teamsRef, teamsCount);
    animateValue(orgnizationRef, organizationsCount);
  }, []);

  return (
    <Box className="bg-white py-12">
      <Container maxWidth="xl">
        <Grid container spacing={isSmallScreen ? 2 : 4} justifyContent="center">
          <Grid item xs={12}>
            <div className="flex flex-col sm:flex-row justify-center gap-20">
              <div className="counter-wrapper">
                <Typography
                  variant="h1"
                  className={`text-2xl ${
                    !isSmallScreen ? "sm:text-3xl md:text-4xl" : ""
                  } font-bold mb-2 text-center`}
                >
                  <span ref={participantsRef} className="count">
                    0
                  </span>
                  +
                </Typography>
                <Typography variant="body1" className="text-center">
                  Participants
                </Typography>
              </div>
              <div className="counter-wrapper">
                <Typography
                  variant="h1"
                  className={`text-2xl ${
                    !isSmallScreen ? "sm:text-3xl md:text-4xl" : ""
                  } font-bold mb-2 text-center`}
                >
                  <span ref={teamsRef} className="count">
                    0
                  </span>
                  +
                </Typography>
                <Typography variant="body1" className="text-center">
                  Teams
                </Typography>
              </div>
              <div className="counter-wrapper">
                <Typography
                  variant="h1"
                  className={`text-2xl ${
                    !isSmallScreen ? "sm:text-3xl md:text-4xl" : ""
                  } font-bold mb-2 text-center`}
                >
                  <span ref={orgnizationRef} className="count">
                    0
                  </span>
                  +
                </Typography>
                <Typography variant="body1" className="text-center">
                  Organizations
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AnimatedCounterSection;
