import React from "react";
import Navbar from "./NavBar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>Learn Coding and Programming in Bangladesh | CSB </title>
        <meta
          name="description"
          content="Bangladesh's fastest-growing platform for learning coding, programming, and job preparation. Join CSB to unlock your career potential in tech."
        />
      </Helmet>

      <Navbar />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
