import React from "react";
import SectionTitle from "../LandingPage/components/SectionTitle";

const AILogo = "/mlTrack.png";
const HealthcareLogo = "/healthTrack.png";
const EducationLogo = "/education.png";
const BlockchainLogo = "/blockchain.png";
const SocialImpactLogo = "/socialTrack.png";
const DeveloperToolsLogo = "/devToolTrack.png";

const tracks = [
  { name: "AI & Machine Learning", imageUrl: AILogo },
  { name: "Healthcare", imageUrl: HealthcareLogo },
  { name: "Education", imageUrl: EducationLogo },
  { name: "Blockchain & Security", imageUrl: BlockchainLogo },
  { name: "Social Impact", imageUrl: SocialImpactLogo },
  { name: "Developer Tools", imageUrl: DeveloperToolsLogo },
];

const TrackCard = ({ name, imageUrl, ...props }) => {
  return (
    <div
      className="overflow-hidden flex flex-col space-y-2 items-center justify-center bg-white rounded-xl"
      {...props} // Spread any additional props
    >
      {/* icon */}
      <div className="w-full">
        <img
          className="h-48 object-cover w-full hover:scale-120 duration-500"
          src={imageUrl}
          alt={name}
        />
      </div>
      {/* title */}
      <h2 className="text-xl py-3 text-center text-csb-500 font-bold">
        {name}
      </h2>
    </div>
  );
};

const TracksSection = () => {
  return (
    <section className="max-w-screen-xl mx-auto px-8 py-12 pt-20">
      <div data-aos="fade-up" data-aos-duration="2000">
        <SectionTitle title="Tracks" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 px-8 lg:px-0 py-8">
        {tracks.map((track, index) => (
          <TrackCard
            key={index}
            name={track.name}
            imageUrl={track.imageUrl}
            data-aos="fade-up"
            data-aos-duration="2300"
          />
        ))}
      </div>
    </section>
  );
};

export default TracksSection;
