import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Fade,
  Grow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import { motion } from "framer-motion";

const questions = [
  "Describe a place you like to go that's near water.",
  "Talk about a skill you'd like to learn and why.",
  "Describe a time when you helped someone.",
  "Talk about a book you've recently read.",
  "Describe your favorite season and why you like it.",
  "Talk about a technology that has changed your life.",
  "Describe a celebration or festival in your culture.",
  "Talk about a goal you have for the next five years.",
  "Describe a time when you had to make a difficult decision.",
  "Talk about a person who has influenced you positively.",
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: "16px",
  boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
}));

const AnimatedTypography = motion(Typography);

const SpokenEnglishTest = ({ onComplete }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const [timeLeft, setTimeLeft] = useState(120);
  const [testCompleted, setTestCompleted] = useState(false);
  const [score, setScore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [testStarted, setTestStarted] = useState(false);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    const shuffled = [...questions].sort(() => 0.5 - Math.random());
    setSelectedQuestions(shuffled.slice(0, 2));
  }, []);

  useEffect(() => {
    let timer;
    if (isRecording && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      stopRecording();
    }
    return () => clearTimeout(timer);
  }, [isRecording, timeLeft]);

  const startRecording = async () => {
    if (!testStarted) {
      setTestStarted(true);
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setRecordings([...recordings, audioUrl]);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setTimeLeft(120);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (currentQuestionIndex < selectedQuestions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setTimeLeft(120); // Reset timer for the next question
      } else {
        setTestCompleted(true);
        setAllQuestionsAnswered(true);
      }
    }
  };

  const sendToGPTForGrading = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      recordings.forEach((recording, index) => {
        formData.append(`audio`, recording);
      });
      selectedQuestions.forEach((question, index) => {
        formData.append(`question${index + 1}`, question);
      });

      const response = await fetch("/grade-english-test", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_CSB_SERVER_API_SECRET}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to grade test");
      }

      const result = await response.json();
      setScore(result.score);
      // You can also use result.fullEvaluation if you want to display more detailed feedback
    } catch (error) {
      console.error("Error grading test:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    onComplete(score, allQuestionsAnswered);
  };

  return (
    <Container maxWidth="md">
      <Fade in={true} timeout={1000}>
        <StyledPaper elevation={3}>
          <AnimatedTypography
            variant="h4"
            gutterBottom
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            English Speaking Test
          </AnimatedTypography>

          <Stepper
            activeStep={currentQuestionIndex}
            alternativeLabel
            sx={{ mb: 4 }}
          >
            {selectedQuestions.map((_, index) => (
              <Step key={index}>
                <StepLabel>Question {index + 1}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {!testCompleted ? (
            <Grow in={true} timeout={500}>
              <Box>
                <AnimatedTypography
                  variant="h6"
                  gutterBottom
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                >
                  {selectedQuestions[currentQuestionIndex]}
                </AnimatedTypography>
                <Box sx={{ my: 4, display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    color={isRecording ? "secondary" : "primary"}
                    startIcon={isRecording ? <StopIcon /> : <MicIcon />}
                    onClick={isRecording ? stopRecording : startRecording}
                    size="large"
                    disabled={testCompleted || (currentQuestionIndex === selectedQuestions.length - 1 && recordings.length === selectedQuestions.length)}
                  >
                    {isRecording ? "Stop Recording" : "Start Recording"}
                  </Button>
                </Box>
                {isRecording && (
                  <Typography variant="h5" align="center" color="primary">
                    Time left: {Math.floor(timeLeft / 60)}:
                    {(timeLeft % 60).toString().padStart(2, "0")}
                  </Typography>
                )}
              </Box>
            </Grow>
          ) : (
            <Fade in={true} timeout={500}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6" gutterBottom>
                  Test Completed
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={sendToGPTForGrading}
                  disabled={isLoading}
                  size="large"
                  sx={{ mt: 2 }}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Get Score"}
                </Button>
              </Box>
            </Fade>
          )}
        </StyledPaper>
      </Fade>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Your IELTS Speaking Score</DialogTitle>
        <DialogContent>
          <Typography
            variant="h2"
            align="center"
            color="primary"
            sx={{ my: 4 }}
          >
            {score}
          </Typography>
          <Typography variant="body1">
            This score is an estimate based on your responses to the two
            questions. In an actual IELTS test, your speaking would be evaluated
            on four criteria: Fluency and Coherence, Lexical Resource,
            Grammatical Range and Accuracy, and Pronunciation.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SpokenEnglishTest;
