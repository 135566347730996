const Colors = {
  primary: "#6674CC",
  primaryVariant: "#3700b3",
  secondary: "#03dac6",
  secondaryVariant: "#018786",
  background: "#ffffff",
  surface: "#ffffff",
  error: "#b00020",
  onPrimary: "#ffffff",
  onSecondary: "#000000",
  onBackground: "#000000",
  onSurface: "#000000",
  onError: "#ffffff",
  white: "#ffffff",
  black: "#000000",
};

export default Colors;
