import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";

function StatsSection() {
  const scholarshipAmount = 800000;
  const jobsLanded = 65;

  const scholarshipRef = useRef(null);
  const jobsRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const animateValue = (ref, targetValue) => {
      let startTimestamp = null;
      const duration = 2000;
      let currentCount = 0;

      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        currentCount = Math.floor(progress * targetValue);
        ref.current.textContent = currentCount.toLocaleString();
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    };

    animateValue(scholarshipRef, scholarshipAmount);
    animateValue(jobsRef, jobsLanded);
  }, []);

  return (
    <Box sx={{ bgcolor: "background.paper", py: 8 }}>
      <Container maxWidth="md">
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Box textAlign="center">
              <Typography
                variant={isSmallScreen ? "h3" : "h2"}
                component="h2"
                color="primary"
                fontWeight="bold"
                gutterBottom
              >
                <span ref={scholarshipRef}>0</span>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Total Scholarships/Financial Aid Given in Taka
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box textAlign="center">
              <Typography
                variant={isSmallScreen ? "h3" : "h2"}
                component="h2"
                color="primary"
                fontWeight="bold"
                gutterBottom
              >
                <span ref={jobsRef}>0</span>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Total Jobs Landed
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default StatsSection;
