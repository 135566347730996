import React from "react";
import {
  Typography,
  Avatar,
  Chip,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Business,
  LocationOn,
  AttachMoney,
  Schedule,
  Check,
  Close,
  Info,
  Refresh,
} from "@mui/icons-material";

const CompanyCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const CardContentNoPadding = styled(CardContent)({
  padding: "16px 16px 0 16px",
  "&:last-child": {
    paddingBottom: 0,
  },
});

// Mock data (replace with actual data from your backend)
const candidateData = {
  name: "Dan Brown",
  avatar:
    "https://cdn1.iconfinder.com/data/icons/user-pictures/100/male3-512.png",
  education: "B.S. Computer Science, KUET",
  workHistory: "5 years, Previously worked at Google",
  englishScore: 8,
  personalityType: "Growth Mindset, Curiosity, Proactivness, Communication",
  problemSolvingScore: 8,
  domainSpecificScore: 9,
};

const interestedCompanies = [
  {
    id: 1,
    name: "Tech Giant Inc.",
    location: "San Francisco, CA",
    position: "Senior Developer",
    salary: "$120,000 - $150,000",
  },
  {
    id: 2,
    name: "Startup Innovators",
    location: "New York, NY",
    position: "Full Stack Engineer",
    salary: "$100,000 - $130,000",
  },
];

const scheduledInterviews = [
  {
    id: 1,
    name: "Global Systems",
    date: "2023-06-15",
    time: "14:00",
    position: "Backend Developer",
  },
  {
    id: 2,
    name: "Data Insights Co.",
    date: "2023-06-18",
    time: "10:30",
    position: "Front-End Engineer",
  },
];

const activeOffers = [
  {
    id: 1,
    name: "Cloud Solutions Ltd.",
    position: "Software Architect",
    salary: "$140,000",
    deadline: "2023-06-30",
  },
  {
    id: 2,
    name: "AI Innovations",
    position: "Software Engineer",
    salary: "$125,000",
    deadline: "2023-07-05",
  },
];

const CandidateDashboard = () => {
  return (
    <section className="max-w-screen-xl mx-auto py-20 px-4 md:px-8">
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Paper
            sx={{
              padding: 3,
              marginBottom: 3,
              display: "flex",
              alignItems: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <Avatar
              src={candidateData.avatar}
              alt={candidateData.name}
              sx={{ width: 100, height: 100, marginRight: 3 }}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {candidateData.name}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {candidateData.education}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {candidateData.workHistory}
              </Typography>
              <Box>
                <Chip
                  label={`English: ${candidateData.englishScore}/9`}
                  color="primary"
                  sx={{ margin: 0.5 }}
                />
                <Chip
                  label={`Personality: ${candidateData.personalityType}`}
                  color="secondary"
                  sx={{ margin: 0.5 }}
                />
                <Chip
                  label={`Problem Solving: ${candidateData.problemSolvingScore}/10`}
                  color="success"
                  sx={{ margin: 0.5 }}
                />
                <Chip
                  label={`Domain Specific: ${candidateData.domainSpecificScore}/10`}
                  color="info"
                  sx={{ margin: 0.5 }}
                />
              </Box>
            </Box>
            <Button
              variant="outlined"
              startIcon={<Refresh />}
              sx={{ alignSelf: "flex-start" }}
            >
              Retake Assessments
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{ marginTop: 4, marginBottom: 2, fontWeight: "bold" }}
          >
            Interested Companies
          </Typography>
          <Grid container spacing={3}>
            {interestedCompanies.map((company) => (
              <Grid item xs={12} md={6} key={company.id}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent
                    sx={{
                      padding: "16px 16px 0 16px",
                      "&:last-child": { paddingBottom: 0 },
                    }}
                  >
                    <Typography variant="h6">{company.name}</Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      <LocationOn fontSize="small" /> {company.location}
                    </Typography>
                    <Typography variant="body1">{company.position}</Typography>
                    <Typography variant="body2">
                      <AttachMoney fontSize="small" /> {company.salary}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" startIcon={<Info />}>
                      Details
                    </Button>
                    <Button size="small" color="primary" startIcon={<Check />}>
                      Accept
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<Close />}
                    >
                      Decline
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{ marginTop: 4, marginBottom: 2, fontWeight: "bold" }}
          >
            Scheduled Interviews
          </Typography>
          <Paper sx={{ marginBottom: 3 }}>
            <List>
              {scheduledInterviews.map((interview) => (
                <ListItem key={interview.id}>
                  <ListItemAvatar>
                    <Avatar>
                      <Business />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={interview.name}
                    secondary={`${interview.position} - ${interview.date} at ${interview.time}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="details">
                      <Info />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{ marginTop: 4, marginBottom: 2, fontWeight: "bold" }}
          >
            Active Offers
          </Typography>
          <Grid container spacing={3}>
            {activeOffers.map((offer) => (
              <Grid item xs={12} md={6} key={offer.id}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent
                    sx={{
                      padding: "16px 16px 0 16px",
                      "&:last-child": { paddingBottom: 0 },
                    }}
                  >
                    <Typography variant="h6">{offer.name}</Typography>
                    <Typography variant="body1">{offer.position}</Typography>
                    <Typography variant="body2">
                      <AttachMoney fontSize="small" /> {offer.salary}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <Schedule fontSize="small" /> Deadline: {offer.deadline}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" startIcon={<Info />}>
                      View Details
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};

export default CandidateDashboard;
