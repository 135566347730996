import React, { useState, useRef } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Box, Select, MenuItem, Button, InputLabel, FormControl } from '@mui/material';
import { interviewQuestions, TIME_LIMIT, COUNT_DOWN_TIME_LIST, INITIAL_INTRO, INTITIAL_QUESTION } from './Constants';

const BackgroundDiv = styled('div')({
  background: `url('/dodo.png')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StartButton = styled(Button)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '40px',
});

const InterviewContainer = styled(Box)({
  backgroundColor: 'white',
  padding: '50px 40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '15px',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  minWidth: '40%',
});

const ActionContainer = styled('div')({
  fontSize: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const StyledButton = styled(Button)({
  width: '40%',
});

const DODO = () => {
  const [position, setPosition] = useState('');
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(INTITIAL_QUESTION);
  const [responses, setResponses] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [isListening, setIsListening] = useState(true);
  const [hasInterviewStarted, setHasInterviewStarted] = useState(false);
  const [silenceTimer, setSilenceTimer] = useState(null);
  const recognition = useRef(new (window.SpeechRecognition || window.webkitSpeechRecognition)());
  const synth = useRef(window.speechSynthesis);
  const silenceTimerRef = useRef();
  const askedQuestions = new Set();
  const navigate = useNavigate();

  // const checkAndPlayText = () => {
  //   const utterance = new SpeechSynthesisUtterance(INITIAL_INTRO);
  //   utterance.voice = synth.current.getVoices().find(voice => voice.name.includes('Aaron'));
  //   utterance.onend = startTimer;
  //   synth.current.speak(utterance);
  // };

  const checkAndPlayText = () => {
    axios.post('/tts', { INITIAL_INTRO })
    .then((response) => {
      // const audio = new Audio(response.data.audioUrl);
      console.log("TTS RESPONSE: ", response.data)
      const audioUrl = URL.createObjectURL(response.data);
      const audio = new Audio(audioUrl);
      audio.play();
      audio.onended = () => {
        startTimer();
      };
    })
    .catch((error) => {
      console.error('Play.ht TTS Error:', error);
    });
  };  

  const startInterview = () => {
    setHasInterviewStarted(true);
    checkAndPlayText();
    recognition.current.continuous = true;
    recognition.current.onresult = handleSpeechResult;
    recognition.current.onend = handleSpeechEnd;
  };

  const handleSpeechResult = (event) => {
    const lastResult = event.results[event.results.length - 1];
    const transcript = lastResult[0].transcript;
    setResponses(prev => [...prev, { role: 'user', content: transcript }]);
    setIsListening(false);
    clearTimeout(silenceTimerRef.current);
    setSilenceTimer(null);
  };

  const handleSpeechEnd = () => {
    if (isListening) {
      moveToNextQuestion();
    }
  };

  const moveToNextQuestion = () => {
    setIsListening(false);
  
    let nextIndex;
    do {
      nextIndex = Math.floor(Math.random() * interviewQuestions.length);
    } while (askedQuestions.has(nextIndex));
  
    if (!askedQuestions.has(nextIndex) && questionIndex < interviewQuestions.length - 1) {
      askedQuestions.add(nextIndex);
      setQuestionIndex(nextIndex);
      setCurrentQuestion("");
      playText(interviewQuestions[nextIndex], startListening);
    } else {
      submitInterview();
    }
  };

  // const playText = (text, callback) => {
  //   const utterance = new SpeechSynthesisUtterance(text);
  //   utterance.voice = synth.current.getVoices().find(voice => voice.name.includes('Aaron'));
  //   utterance.onend = () => {
  //     if (callback) callback();
  //   };
  //   synth.current.speak(utterance);
  // };

  const playText = (text, callback) => {
    const config = {
      headers: { 'Authorization': 'Bearer 4M0FcdPbIwgncabd0mNjYvJFw3K2' },
    };
    const data = {
      voice: '3d934d28d1367b4945186fe06e834600bb9473c6d1f5d71bdcde3d895d7f5899',
      text: text,
    };
  
    axios.post('https://api.play.ht/api/v1/tts/synthesize', data, config)
      .then((response) => {
        const audio = new Audio(response.data.url);
        audio.play();
        audio.onended = () => {
          if (callback) {
            callback();
          }
        };
      })
      .catch((error) => {
        console.error('Play.ht TTS Error:', error);
      });
  };  

  const startListening = () => {
    setIsListening(true);
    recognition.current.start();
    silenceTimerRef.current = setTimeout(() => {
      setSilenceTimer(TIME_LIMIT);
    }, COUNT_DOWN_TIME_LIST);
  };

  const startTimer = () => {
    clearTimeout(silenceTimerRef.current);
    silenceTimerRef.current = setInterval(() => {
      setSilenceTimer(prev => prev > 0 ? prev - 1 : 0);
    }, 1000);
  };

  const submitInterview = () => {
    axios.post('http://127.0.0.1:5000/ask', { position, responses })
      .then((res) => {
        console.log("Result", res.data);
        setFeedback(res.data.feedback);
        navigate('/feedback', { state: { result: res.data } });
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  return (
    <BackgroundDiv>
    {!hasInterviewStarted ? (
      <StartButton variant="contained" color="primary" onClick={startInterview}>
        Start Interview
      </StartButton>
    ) : (
      <InterviewContainer>
        {silenceTimer !== null && startListening == false && (
          <div className="timer">{`Time Left: ${silenceTimer}`}</div>
        )}
        <FormControl fullWidth>
          <InputLabel id="position-select-label">Select Position</InputLabel>
          <Select
            labelId="position-select-label"
            id="position-select"
            value={position}
            label="Select Position"
            onChange={(e) => setPosition(e.target.value)}
          >
            <MenuItem value="intern">Intern</MenuItem>
            <MenuItem value="full-time">Full-Time</MenuItem>
          </Select>
        </FormControl>
        <div className="question">{`DODO: ${currentQuestion}`}</div>
        <div className="responses">
          {responses.slice(-1).map((response, index) => (
            <p key={index}>{`You: ${response.content}`}</p>
          ))}
        </div>
        <ActionContainer>
          <StyledButton variant="contained" color="primary" onClick={startListening} disabled={!isListening}>
            Speak
          </StyledButton>
          <StyledButton variant="contained" color="primary" onClick={moveToNextQuestion}>
            Next Question
          </StyledButton>
        </ActionContainer>
        <Button variant="contained" color="secondary" onClick={submitInterview}>
          Debug API
        </Button>
      </InterviewContainer>
    )}
  </BackgroundDiv>
  );
};

export default DODO;