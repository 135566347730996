import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-white w-full px-8 py-6">
      <div className="max-w-screen-lg mx-auto">
        <div className="flex flex-col lg:flex-row justify-between items-center space-y-3 lg:space-y-0">
          {/* copyright  */}
          <div>
            <p className="text-sm text-gray-500">
              © 2024 Coding Shikhbe Bangladesh. All rights reserved
            </p>
          </div>

          {/* social handle and email  */}
          <div>
            {/* social  */}
            <div className="flex flex-row items-center justify-center lg:justify-end text-xl space-x-2">
              <a
                className="hover:text-csb-500"
                target="_blank"
                rel="nonreferer noreferrer"
                href="https://www.facebook.com/csbmain"
              >
                <FaFacebook />
              </a>
              <a
                className="hover:text-csb-500"
                target="_blank"
                rel="nonreferer noreferrer"
                href="https://www.youtube.com/@csbdmain"
              >
                <FaYoutube />
              </a>
              <a
                className="hover:text-csb-500"
                target="_blank"
                rel="nonreferer noreferrer"
                href="https://www.linkedin.com/company/csbmain/"
              >
                <FaLinkedin />
              </a>
            </div>

            {/* email  */}
            <div>
              <p className="text-sm text-gray-500">
                Contact: hello@csbmain.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
