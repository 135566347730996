import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionTitle from "../LandingPage/components/SectionTitle";

const faqs = [
  {
    question: "What is a Hackathon?",
    answer:
      "A hackathon is where you turn your crazy ideas into real projects. At HackCSB, for 1 week, you'll be learning and building in an energetic environment with people as passionate as you are! Not to mention, some epic prizes, world-renowned guest speakers, incredible mentors, and deeply technical workshops. Basically, you take care of hacking and we'll take care of you!",
  },
  {
    question: "Is there a registration fee?",
    answer: "Registration fee for this event is 200 Taka",
  },
  {
    question:
      "What is the difference between a hackathon, a CP contest, or any other competition?",
    answer:
      "A hackathon focuses on creating a functional project or prototype, while a Competitive Programming (CP) contest emphasizes solving algorithmic problems. Hackathons are about building and presenting a complete product, whereas CP contests are about solving coding challenges quickly and accurately.",
  },
  {
    question:
      "Why do people participate in Hackathon instead of doing CP contests?",
    answer:
      "Hackathons provide a collaborative environment where participants can create tangible projects and products. They offer opportunities for networking, real-world problem-solving, and showcasing creativity. CP contests are more individual-focused and emphasize problem-solving skills.",
  },
  {
    question: "Do I need a team to participate?",
    answer: "No, you can participate as an individual or form a team.",
  },
  {
    question: "What can I build?",
    answer:
      "Anything your heart desires! Hardware, web, mobile, gaming, VR...you name it, we'll support it. We have six tracks that will have extra resources.",
  },
  {
    question: "How do judges evaluate the project?",
    answer:
      "Judges typically look at criteria like innovation, functionality, design, user experience, and the project's overall impact. Presentation skills can also be important",
  },
];

const FAQsSection = () => {
  return (
    <section className="max-w-screen-xl mx-auto px-8 py-12">
      <div data-aos="fade-up" data-aos-duration="1000">
        <SectionTitle title="FAQ" />
      </div>

      {/* maincontent  */}

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 w-full gap-20 pt-12">
        {/* faqs  */}
        <div className="col-span-1 md:col-span-2 lg:col-span-3 flex flex-col space-y-5">
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              className="py-4 border-none rounded-xl shadow-none  "
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h1 className="text-csb-500 font-bold">{faq?.question}</h1>
              </AccordionSummary>
              <AccordionDetails className="text-sm text-gray-700">
                {faq?.answer}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>

        {/* vectors */}
        <div className="col-span-1 md:col-span-1 lg:col-span-2">
          <img
            className="object-cover"
            src="../../../faq.svg"
            alt="faq"
            data-aos="fade-up"
            data-aos-duration="1200"
          />
        </div>
      </div>
    </section>
  );
};

export default FAQsSection;
