import React, { Fragment } from "react";
import SectionTitle from "./SectionTitle";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { MdOutlineAutoGraph } from "react-icons/md";
import SingleDiv from "./SingleDiv";
import { BsLightning } from "react-icons/bs";

const data = [
  {
    id: 1,
    title: "Qualitiful CS Learning",
    icon: <HiMiniComputerDesktop />,
    description:
      "CSB provides a rigorous, contextually relevant curriculum designed to elevate your coding proficiency and tech competence, ensuring you meet global industry standards.",
  },
  // {
  //   id: 2,
  //   title: "Make domestic talents",
  //   icon: <MdOutlineAutoGraph />,
  //   description:
  //     "By nurturing local talent with world-class skills, CSB aims to strengthen Bangladesh's tech workforce, enabling them to compete on both a national and international stage",
  // },
  // {
  //   id: 3,
  //   title: "Develop Technological Entrepreneurship",
  //   icon: <BsLightning />,
  //   description:
  //     "CSB fosters innovation by equipping aspiring tech entrepreneurs with the knowledge and skills necessary to launch successful ventures and drive technological advancement.",
  // },
  {
    id: 2,
    title: "Hire Top 1% of Talent",
    icon: <MdOutlineAutoGraph />,
    description:
      "Hire top 1% of tech talent at a fraction of the cost and 90% faster. We will take of all the compliances and paperwork.",
  },
  {
    id: 3,
    title: "Get High-Paid Remote Jobs",
    icon: <BsLightning />,
    description:
      "Get access to high-paid remote jobs from developed countries. We will help you with the interview process and paperwork.",
  },
];

const WhyCSBSection = () => {
  return (
    <section className="py-12 max-w-screen-xl mx-auto">
      <div data-aos="fade-up-right">
        <SectionTitle title="Why CSB ?" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 lg:px-0 py-8">
        {data?.map((item) => {
          return (
            <Fragment key={item?.id}>
              <SingleDiv {...item} />
            </Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default WhyCSBSection;
